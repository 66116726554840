import React from "react";

var styles = {
  inputDisabled: {
    backgroundColor: "rgb(230, 226, 226)",
  },
};

interface LabelInputProps {
  required?: boolean;
  updateItem: (id: string) => (ev: any) => void;
  value: any;
  defaultValue?: any;
  id: string;
  type?: any;
  wrapperClass?: string;
  disabled?: boolean;
}

export default class LabelInput extends React.Component<LabelInputProps> {
  render() {
    const required =
      typeof this.props.required === "undefined" ? true : this.props.required;

    const type = this.props.type || "text";

    var requiredClassName = required ? "required" : "";

    var disabledInput = (
      <input
        style={styles.inputDisabled}
        className={requiredClassName}
        type={type}
        id={this.props.id}
        value={this.props.value}
        defaultValue={this.props.defaultValue}
        onChange={this.props.updateItem(this.props.id)}
        disabled
      />
    );

    var Input = (
      <input
        className={requiredClassName}
        type={type}
        id={this.props.id}
        value={this.props.value}
        defaultValue={this.props.defaultValue}
        onChange={this.props.updateItem(this.props.id)}
      />
    );

    return (
      <div
        className={
          this.props.wrapperClass
            ? this.props.wrapperClass
            : "input-receiving-wrapper"
        }
      >
        <label htmlFor={this.props.id} style={{ fontSize: "0.8em" }}>
          {this.props.children}
        </label>
        {this.props.disabled ? disabledInput : Input}
      </div>
    );
  }
}
