import React from "react";

interface HeaderProps {
  url: string;
}

export class Header extends React.Component<HeaderProps> {
  render() {
    const client = window.location.pathname.split("/")[2];
    let stl = {};
    switch (client) {
      case "bookspan":
        stl = { background: "#558E55" };
        break;
      case "vtz":
        stl = { background: "#750A0A" };
        break;
      case "ngs":
        stl = { background: "#A58402" };
        break;
      default:
        break;
    }
    return (
      <header
        data-role="header"
        data-theme="b"
        className="ui-title"
        style={stl}
      >
        <p>
          Logged In As {"TODO: show logged in user"} -{" "}
          <a href="/logout">Logout</a>
        </p>
        <h3>
          <a href={this.props.url}>{this.props.children}</a>
        </h3>
      </header>
    );
  }
}
