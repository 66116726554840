export function prettifyLocation(locationId: any) {
  let pretty;
  locationId = locationId + ""; //stringifying locationId;
  if (locationId.length >= 7) {
    pretty =
      locationId.substring(0, 3) +
      "-" +
      locationId.substring(3, 6) +
      "-" +
      locationId.substring(6, 7) +
      " " +
      locationId.substring(7);
  } else if (locationId.length >= 6) {
    pretty =
      locationId.substring(0, 3) +
      "-" +
      locationId.substring(3, 6) +
      "-" +
      locationId.substring(6);
  }
  return pretty || locationId;
}
