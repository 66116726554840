import React from "react";
import { useState } from "react";
import { Header } from "../../../components/common/Header";
import Linput from "../../../components/common/Linput";

let style = {
  mainDiv: {
    textAlign: "center" as "center",
  },
  input: {
    float: "none",
  },
};

const SendOSM: React.FC = (props) => {
  const [notice, setNotice] = useState(null);
  const sendFetch = async () => {
    const response = await fetch(`/api/osm/sync`, {
      credentials: "include",
      method: "POST",
    });
    if (response.status !== 200) {
      setNotice(await response.text());
    } else {
      setNotice("OSM File Processing, check email for receipt record");
    }
  };

  return (
    <div style={style.mainDiv}>
      <Header url="./">Send OSM File</Header>
      <div>
        <button onClick={() => sendFetch()}>Send File</button>
      </div>
      {notice && (
        <div>
          <h1>{notice}</h1>
        </div>
      )}
    </div>
  );
};

export default SendOSM;
