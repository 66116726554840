import React from "react";

import { Header } from "../../../components/common/Header";
import Button from "../../../components/common/Button";
import { Link } from "react-router-dom";
import { useState } from "react";

const BulkSatoTitlesConfirmDelete = () => {
  const [deleted, setDeleted] = useState<boolean>(false);

  const deleteAll = async () => {
    await fetch("/api/sato/titles", {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    });
    setDeleted(true);
  };

  return (
    <>
      <Header url="/management">Bulk Sato Titles</Header>
      <Link to={"/management/bulk-sato-titles"} style={{ width: "50%" }}>
        Back
      </Link>
      {!deleted ? (
        <div style={{ textAlign: "center" }}>
          Are you sure you want to delete all bulk sato titles?
          <br></br>
          <Button onClick={deleteAll.bind(this)}>Yes</Button>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}> Deleted!</div>
      )}
    </>
  );
};

export default BulkSatoTitlesConfirmDelete;
