import React from "react";
import { Link } from "react-router-dom";
import { useClientList } from "../../contexts/ClientContext";

interface genericFunction {
  (props?: any): any;
}

const ClientPage: React.FC = () => {
  const clients = useClientList();
  return (
    <div id="page-container">
      {clients.map((client) => (
        <div key={client.id} className="row">
          <div style={{ background: client.color }}>
            <Link
              to={`/client/${client.id}`}
              style={{
                color: "#fff",
                display: "block",
                margin: "0 auto",
                textAlign: "center",
                padding: "20px",
                fontSize: "20pt",
              }}
            >
              {client.name}
            </Link>
          </div>
        </div>
      ))}
      <div className="row">
        <div style={{ background: "#ccc" }}>
          <Link
            to={`/management`}
            style={{
              color: "#fff",
              display: "block",
              margin: "0 auto",
              textAlign: "center",
              padding: "20px",
              fontSize: "20pt",
            }}
          >
            Warehouse Management
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ClientPage;
