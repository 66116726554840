import { createSetter } from "./helpers";

export const actionTypes = {
  SET_SPINNER_VISIBILITY: "SET_SPINNER_VISIBILITY",
  SET_ERROR: "SET_ERROR",
  SET_MODAL_VISIBILITY: "SET_MODAL_VISIBILITY",
  SET_INPUT_TERM: "SET_INPUT_TERM",
};

export const actions = {
  setSpinnerVisibility: createSetter(
    actionTypes.SET_SPINNER_VISIBILITY,
    "visibility",
  ),
  setModalVisibility: createSetter(
    actionTypes.SET_MODAL_VISIBILITY,
    "visibility",
  ),
  setError: createSetter(actionTypes.SET_ERROR, "error"),
};
