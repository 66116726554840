import React from "react";
import Home from "./components/Home";
import { Redirect } from "react-router-dom";
import { useCurrentClient } from "../../contexts/ClientContext";

const IndexHome: React.FC = () => {
  const client = useCurrentClient();
  if (!client) {
    return <Redirect to="/client" />;
  }
  return <Home blockColor={client.color} clientName={client.name} />;
};

export default IndexHome;
