import React from "react";
import InputLabel from "../../../../components/common/InputLabel";
import Note from "../../../../components/common/note";

export default (props) => {
  return (
    <div id="detailsDiv">
      <Note>*Required fields in red</Note>

      <form className="detailsForm">
        <div>
          <InputLabel
            wrapperClass="input-receiving-wrapper"
            disabled={true}
            updateItem={props.updateItem}
            value={props.item.locationId}
            id="locationId"
          >
            Location Id:
          </InputLabel>

          <InputLabel
            wrapperClass="ir-title-wrapper"
            required={false}
            updateItem={props.updateItem}
            value={props.item.status}
            id="status"
          >
            Status(A/F/D)[Remove sku/inventory prior to disabling]:
          </InputLabel>

          <InputLabel
            updateItem={props.updateItem}
            value={props.item.locationType}
            id="locationType"
          >
            Location:
          </InputLabel>

          <InputLabel
            updateItem={props.updateItem}
            value={props.item.skuOwner}
            id="skuOwner"
          >
            Sku Owner Code(Bookspan : 6, Vetz 90, NGS 94, Uncle Harry 98):
          </InputLabel>

          <InputLabel
            updateItem={props.updateItem}
            value={props.item.clientId}
            id="clientId"
          >
            Client Id:
          </InputLabel>

          <InputLabel
            updateItem={props.updateItem}
            value={props.item.coId}
            id="coId"
          >
            Co Id:
          </InputLabel>

          <InputLabel
            updateItem={props.updateItem}
            value={props.item.clubId}
            id="clubId"
          >
            Club Id:
          </InputLabel>

          <InputLabel
            updateItem={props.updateItem}
            value={props.item.height}
            id="height"
          >
            Height:
          </InputLabel>

          <InputLabel
            updateItem={props.updateItem}
            value={props.item.length}
            id="length"
          >
            Length:
          </InputLabel>

          <InputLabel
            updateItem={props.updateItem}
            value={props.item.width}
            id="width"
          >
            Width:
          </InputLabel>

          <h1 style={{ clear: "both" }}>
            For reference, location currently has :{" "}
          </h1>

          <InputLabel
            wrapperClass="input-receiving-wrapper"
            disabled={true}
            updateItem={props.updateItem}
            value={props.item.sku}
            id="sku"
          >
            Sku:
          </InputLabel>

          <InputLabel
            wrapperClass="input-receiving-wrapper"
            disabled={true}
            updateItem={props.updateItem}
            value={props.item.title}
            id="stitleku"
          >
            Title:
          </InputLabel>

          <InputLabel
            wrapperClass="input-receiving-wrapper"
            disabled={true}
            updateItem={props.updateItem}
            value={props.item.available}
            id="available"
          >
            Available:
          </InputLabel>

          <InputLabel
            wrapperClass="input-receiving-wrapper"
            disabled={true}
            updateItem={props.updateItem}
            value={props.item.onHand}
            id="onHand"
          >
            On Hand:
          </InputLabel>
        </div>
      </form>
    </div>
  );
};
