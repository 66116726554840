export function createSetter(type, key) {
  return function (val) {
    return {
      type: type,
      [key]: val,
    };
  };
}

export function createSetterReducer(defaultState, type, key) {
  return function (state = defaultState, action) {
    if (action.type === type) return action[key];
    return state;
  };
}
