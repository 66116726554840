import React from "react";

function getClassname(locType) {
  switch (locType) {
    case "VNAP":
      return "vnap-e";
    case "STDP":
      return "stdp-e";
    case "STDP CABINET":
      return "stdpcab-e";
    default:
      return "";
  }
}

const Locations = ({ locations, updateDropOffId }) => {
  const currentLocations = locations.current.map((item, idx) => {
    return (
      <li key={item.id}>
        <button key={idx} onClick={updateDropOffId.bind(null, item.id)}>
          {"QTY(" + item.available + ")" + "-" + item.type + " - " + item.id}
        </button>
      </li>
    );
  });

  const emptyLocations = locations.empty.map((item, idx) => {
    return (
      <li key={item.id}>
        <button
          key={idx}
          className={getClassname(item.type)}
          onClick={updateDropOffId.bind(null, item.id)}
        >
          {item.type + " - " + item.id}
        </button>
      </li>
    );
  });

  return (
    <div className="locations-cl">
      <h1>Existing Locations</h1>
      <ul>{currentLocations}</ul>

      <h1>Empty Locations</h1>
      <ul>{emptyLocations}</ul>
    </div>
  );
};

export default Locations;
