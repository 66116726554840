import { Header } from "../../../components/common/Header";
import React from "react";
import { useEffect, useState } from "react";
import { withCurrentUser, User } from "../../../contexts/UserContext";
import FakeTable from "../../../components/common/FakeTable";
import { MoveItem } from "../../../components/Moves/MoveItem";
import Spinner from "../../../components/common/Spinner";
import fetch from "isomorphic-fetch";

const movesHeaders = ["Cart", "Deliver To", "Sku", "Move Qty", "Order Id"];
const movesKeys = ["pickup", "dropOff", "sku", "moveQuantity", "orderId"];

interface ReturnsMovesProps {
  currentUser: User;
}

const ReturnsMoves = ({ currentUser }: ReturnsMovesProps) => {
  const [movesList, setMovesList] = useState([]);
  const [inserts, setInserts] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    getMoves();
  }, []);

  const completeMove = async (moveId) => {
    const isSure = confirm("Are you sure you want to complete this move?");
    if (!isSure) return;
    setShowSpinner(true);
    try {
      await fetch(`/api/move/returns/complete/${moveId}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });
    } catch (err) {
      console.error("Error completing move:", err);
    } finally {
      setShowSpinner(false);
      getMoves();
    }
  };

  const unlockMove = async (moveId) => {
    setShowSpinner(true);
    try {
      await fetch(`/api/move/unlock/${moveId}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          lockUserId: currentUser.id,
        }),
      });
    } catch (err) {
      console.error("Error unlocking move:", err);
    } finally {
      setShowSpinner(false);
      getMoves();
    }
  };

  const getMoves = async () => {
    try {
      const data = await fetch(`/api/move?movetypes=Return&status=PENDING`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      let moves = await data.json();
      moves = moves
        .map((m) => (m.lockUserId ? { ...m, className: "taken-move" } : m))
        .sort((a, b) => a.pickup - b.pickup);
      setMovesList(moves);
      setInserts([]);
    } catch (err) {
      console.error(err);
    }
  };

  const lockMove = async (moveId) => {
    setShowSpinner(true);
    try {
      await fetch(`/api/move/lock/${moveId}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          lockUserId: currentUser.id,
        }),
      });
    } catch (err) {
      console.error("Error locking move:", err);
    } finally {
      setShowSpinner(false);
      getMoves();
    }
  };

  const updateInsert = async (moveId) => {
    const move = movesList.find((m) => m.id === moveId);
    if (!move) return;
    const index = movesList.indexOf(move);
    let buttonInnards = `Lock as ${currentUser.username}`;
    let btnOnClick = () => lockMove(moveId);
    let completeEl;
    let className = index % 2 === 0 ? "ft-even" : "ft-odd";
    if (move.lockUserId === currentUser.id) {
      className = "users-move";
      buttonInnards = "Unlock";
      btnOnClick = () => unlockMove(moveId);
      completeEl = (
        <button
          style={{ marginLeft: "10px" }}
          onClick={() => completeMove(moveId)}
        >
          Complete
        </button>
      );
    } else if (move.lockUserId) {
      buttonInnards = `Taken by user id ${move.lockUserId}`;
      btnOnClick = () => completeMove(moveId);
    }
    var insert = {
      insertId: moveId,
      className,
      el: (
        <MoveItem
          title={move.title}
          sku={move.sku}
          pickup={move.pickup}
          dropOff={move.dropOff}
          quantity={move.moveQuantity}
        >
          <button onClick={btnOnClick}>{buttonInnards}</button>
          {completeEl}
        </MoveItem>
      ),
    };
    setInserts([insert]);
  };

  return (
    <div>
      <Spinner show={showSpinner} />
      <Header url="./">Returns Dashboard - Moves</Header>
      <FakeTable
        header={movesHeaders}
        data={movesList}
        keys={movesKeys}
        inserts={inserts}
        rowClicked={(move) => {
          updateInsert(move.id);
        }}
      />
    </div>
  );
};

export default withCurrentUser(ReturnsMoves);
