import React from "react";
import { SimpleSelect } from "./form-components";

let reasonCodes = require("../../data/reason-codes.json");

let reasonCodeAsOptions = Object.keys(reasonCodes).map(function (k) {
  return { value: k, displayText: reasonCodes[k].description };
});
reasonCodeAsOptions.unshift({
  value: "",
  displayText: "Select Reason Code to Continue",
});

export default function ReasonCommit(props: any) {
  return (
    <div
      style={{
        marginTop: "5px",
        marginBottom: "5px",
        float: "right",
        width: "40%",
      }}
      className={props.className}
    >
      <SimpleSelect
        options={reasonCodeAsOptions}
        onChange={props.reasonCodeChange}
      />
      <input
        type="text"
        placeholder="Comments/Notes[optional]"
        style={{ width: "100%", marginTop: "5px", marginBottom: "5px" }}
        onChange={props.onTextChange}
        value={props.commentsText}
      />
      {props.showCommitButton ? (
        <button className="warning" style={props.style} onClick={props.commit}>
          {props.commitText || "Commit"}
        </button>
      ) : (
        ""
      )}
    </div>
  );
}
