import React from "react";
import fetch from "isomorphic-fetch";
import { withCurrentUser } from "../../../contexts/UserContext";
import { User } from "../../../contexts/UserContext";
import { Header } from "../../../components/common/Header";
import FakeTable from "../../../components/common/FakeTable";
import { actions, store } from "../rdx/pending-moves";
import { MoveItem } from "../../../components/Moves/MoveItem";
import Spinner from "../../../components/common/Spinner";
let prevMoveId;
let prevMoveIdPred;

function sortPredictiveMoves(a, b) {
  return a.sku - b.sku;
}

interface NeedsMovesProps {
  currentUser: User;
}

class Needs extends React.Component<NeedsMovesProps, any> {
  constructor(props) {
    super(props);
    this.state = store.getState();
    this.getPredictiveMoves();
    this.getMoves();
  }

  componentWillMount() {
    store.subscribe(() => this.setState(store.getState()));
  }

  async completeMove(moveId) {
    const isSure = confirm("Are you sure?");
    if (!isSure) return;
    prevMoveId = moveId;
    store.dispatch(actions.setSpinnerVisibility(true));
    try {
      await fetch(`/api/move/complete/${moveId}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });
      setTimeout(
        () => store.dispatch(actions.setSpinnerVisibility(false)),
        2000,
      );
      return this.getMoves();
    } catch (err) {
      prevMoveId = "";
      console.error("Error completing move:", err);
    }
  }

  async unlockMove(moveId) {
    store.dispatch(actions.setSpinnerVisibility(true));
    try {
      await fetch(`/api/move/unlock/${moveId}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          lockUserId: this.props.currentUser.id,
        }),
      });
      this.getMoves();
    } catch (err) {
      console.error("Error unlocking move:", err);
    } finally {
      store.dispatch(actions.setSpinnerVisibility(false));
    }
  }

  async getPredictiveMoves() {
    try {
      const res = await fetch(`/api/predictive/6`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });
      const moves = await res.json();
      store.dispatch(
        actions.setPredictiveMovesList(
          moves.sort(sortPredictiveMoves).slice(0, 50),
        ),
      );
      store.dispatch(actions.deletePredictiveMove(this.state.ignoreList));
    } catch (err) {
      console.error("Error getting predictive moves:", err);
    }
  }

  async getMoves() {
    try {
      const res = await fetch(`/api/move?status=PENDING&movetypes=PREDICTIVE`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      const moves = await res.json();
      const insertId =
        typeof this.state.inserts[0] === "undefined"
          ? ""
          : this.state.inserts[0].insertId;
      let iid;
      store.dispatch(
        actions.setMovesList(
          moves
            .map((m) => {
              let ret = Object.assign({}, m);
              if (m.id === insertId) iid = m.id;
              if (m.lockUserId) ret.className = "taken-move";
              if (m.lockUserId === this.props.currentUser.id)
                ret.className = "users-move";
              return ret;
            })
            .sort((a, b) => {
              if (a.dropOff > b.dropOff) return -1;
              if (a.dropOff < b.dropOff) return 1;
              if (a.pickup < b.pickup) return -1;
              if (a.pickup > b.pickup) return 1;
            }),
        ),
      );
      this.updateInsert(iid);
    } catch (err) {
      console.error("Error getting moves:", err);
    }
  }

  updatePredictiveInsert(moveId) {
    const move = this.state.predictiveMovesList.find((m) => m.id === moveId);
    if (!move) return;
    var insert = {
      insertId: move.id,
      className: "prd-insert",
      el: (
        <MoveItem
          title={move.title}
          sku={move.sku}
          pickup={move.pickup}
          dropOff={move.dropOff}
          quantity={move.moveQuantity}
        >
          <button
            onClick={() => {
              this.createMove(move);
            }}
          >
            Make Move Real
          </button>
        </MoveItem>
      ),
    };
    store.dispatch(actions.setPredictiveInserts([insert]));
  }

  async lockMove(moveId) {
    store.dispatch(actions.setSpinnerVisibility(true));
    try {
      await fetch(`/api/move/lock/${moveId}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          lockUserId: this.props.currentUser.id,
        }),
      });
      this.getMoves();
    } catch (err) {
      console.error("Error locking move:", err);
    } finally {
      store.dispatch(actions.setSpinnerVisibility(false));
    }
  }

  updateInsert(moveId) {
    const move = this.state.movesList.filter((m) => m.id === moveId)[0];
    if (!move) return;
    const index = this.state.movesList.indexOf(move);
    let buttonInnards = `Lock as ${this.props.currentUser.username}`;
    let btnOnClick = this.lockMove.bind(this, move.id);
    let completeEl;
    let className = index % 2 === 0 ? "ft-even" : "ft-odd";
    if (move.lockUserId === this.props.currentUser.id) {
      className = "users-move";
      buttonInnards = "Unlock";
      btnOnClick = this.unlockMove.bind(this, move.id);
      completeEl = (
        <button
          style={{ marginLeft: "10px" }}
          onClick={this.completeMove.bind(this, move.id)}
        >
          Complete
        </button>
      );
    } else if (move.lockUserId) {
      buttonInnards = `Taken by ${move.lockUserId}`;
      btnOnClick = () => this.completeMove.bind(this, move.id);
    }
    var insert = {
      insertId: move.id,
      className: className,
      el: (
        <MoveItem
          title={move.title}
          sku={move.sku}
          pickup={move.pickup}
          dropOff={move.dropOff}
          quantity={move.moveQuantity}
        >
          <button onClick={btnOnClick}>{buttonInnards}</button>
          {completeEl}
        </MoveItem>
      ),
    };
    store.dispatch(actions.setInserts([insert]));
  }

  async createMove(move) {
    if (
      move.pick_up + "" + move.drop_off + "" + move.quantity ===
      prevMoveIdPred
    )
      return;
    prevMoveIdPred = move.pick_up + "" + move.drop_off + "" + move.quantity;
    store.dispatch(actions.setSpinnerVisibility(false));
    try {
      const { pickup, dropOff, quantity, sku } = move;
      await fetch(`/api/move`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          pickup,
          dropOff,
          moveQuantity: quantity,
          sku,
          isNormal: true,
          status: "PENDING",
          type: "PREDICTIVE",
        }),
      });
      this.getMoves();
      store.dispatch(actions.addIgnoreId(move.id));
      store.dispatch(actions.deletePredictiveMove(this.state.ignoreList));
    } catch (err) {
      console.error("AN EROROR");
      console.error(err);
    } finally {
      prevMoveIdPred = "";
      store.dispatch(actions.setSpinnerVisibility(false));
    }
  }

  updatePredictiveMoves(move) {
    let cl = "ttl-11-x";
    if (move.type === "Red") {
      cl = "ttl-0-0";
    } else if (move.type === "Yellow") {
      cl = "ttl-1-3";
    }
    move.className = cl;
    move.id = move.pick_up + "-" + move.quantity + "-" + move.sku;
    return move;
  }

  render() {
    return (
      <div>
        <Spinner show={this.state.showSpinner} />
        <Header url="./">Inventory Dashboard - Move</Header>
        <FakeTable
          header={this.state.movesHeaders}
          data={this.state.movesList}
          keys={this.state.movesKeys}
          inserts={this.state.inserts}
          rowClicked={(move) => {
            this.updateInsert(move.id);
          }}
        />
        <h1 style={{ textAlign: "center", margin: "20px" }}>
          Predictive Moves
        </h1>
        <FakeTable
          header={this.state.predictiveMovesHeaders}
          data={this.state.predictiveMovesList
            .map(this.updatePredictiveMoves)
            .sort((a, b) => {
              if (a.type === "Red" && b.type === "Yellow") return -1;
              if (a.type === "Yellow" && b.type === "Red") return 1;
            })}
          keys={this.state.predictiveMovesKeys}
          inserts={this.state.predictiveInserts}
          rowClicked={(move) => this.updatePredictiveInsert(move.id)}
        />
      </div>
    );
  }
}

export default withCurrentUser(Needs);
