import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useInterval } from "../../../utils/Hooks";

import { QueueStats } from "../../../../api/print/PrintDb";
import {
  QueueGroup,
  categorizeQueue,
  addQueueToGroup,
} from "../../../utils/printQueueHelpers";
import { UserContext } from "../../../contexts/UserContext";

const PrintBusinessSelect: React.FC = () => {
  const [queueGroups, setQueueGroup] = useState<QueueGroup[]>(null);
  const [selectedGroups, setSelectedGroups] = useState<QueueGroup[]>([]);

  const [printing, setPrinting] = useState(true);
  const [printQuantity, setPrintQuantity] = useState<string | number>(500);
  const [includeBulkSato, setIncludeBulkSato] = useState([]);
  const [slaHours, setSlaHours] = useState(0);
  const [printSlaOnly, setPrintSLaOnly] = useState(false);
  const [printHandline2Only, setPrintHandline2Only] = useState(false);

  const { warehouseLocation } = useContext(UserContext);
  const [isGLF, setIsGlf] = useState(false);

  useEffect(() => {
    if (!isGLF && warehouseLocation === "GLF") {
      setIsGlf(true);
    }
  }, []);

  const { clientId, businessId } = useParams<{
    clientId: string;
    businessId: string;
  }>();

  const updatePrintingStatus = async () => {
    const data = await fetch(`/api/print/status`, {
      credentials: "include",
    });
    const { printing: status } = await data.json();
    setPrinting(status);
  };

  useInterval(async () => {
    await updatePrintingStatus();
  }, 10 * 1000);

  const refreshQueueList = async () => {
    await updatePrintingStatus();
    const res = await fetch(`/api/print/${clientId}/${businessId}/sla/${sla}`, {
      credentials: "include",
    });
    const queuesRaw: QueueStats[] = (await res.json()).map((q) => ({
      name: q.name,
      totalOrders: parseInt(q.totalOrders),
      totalSlaIssues: parseInt(q.totalSlaIssues),
      totalMachineableOrders: parseInt(q.totalMachineableOrders),
      totalBulkSatoOrders: parseInt(q.totalBulkSatoOrders),
      totalBulkSatoSlaIssues: parseInt(q.totalBulkSatoSlaIssues),
      bulkSato: true,
      machineable: true,
    }));
    const queuesFormatted: { [key: string]: QueueGroup } = queuesRaw.reduce(
      (acc: { [key: string]: QueueGroup }, curr: QueueStats) => {
        const category = categorizeQueue(curr.name);
        if (!acc[category]) {
          acc[category] = {
            name: category,
            totalOrders: 0,
            totalSlaIssues: 0,
            totalMachineableOrders: 0,
            totalBulkSatoOrders: 0,
            totalBulkSatoSlaIssues: 0,
            bulkSato: true,
            machineable: true,
            subQueues: [],
          } as QueueGroup;
        }
        acc[category] = addQueueToGroup(curr, acc[category]);
        return acc;
      },
      {},
    );
    setQueueGroup(Object.values(queuesFormatted));
  };

  const requestPrint = async () => {
    const res = await fetch(`/api/print/${clientId}/${businessId}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        queues: selectedGroups.flatMap((g) => g.subQueues.map((q) => q.name)),
        quantity: printQuantity,
        includeBulkSato,
        slaHours: printSlaOnly ? slaHours : 0,
        handline2: printHandline2Only,
      }),
    });
  };

  let location = useLocation();
  let sla;
  // Quick and hacky for now. Use a library like query-string or qs for real parsing if more state is pushed into the url
  if (location.search.startsWith("?slaHours=")) {
    sla = parseInt(location.search.substring(10));
  } else {
    sla = 24;
  }

  useEffect(() => {
    setSlaHours(sla);
    refreshQueueList();
  }, []);

  if (!queueGroups) {
    return <div>Loading...</div>;
  }

  function handleSlaChange(event: any) {
    setSlaHours(event.target.value);
  }

  const headers = ["", "Queue Name", "Total Orders", "SLA Risk"];

  const bulkSatoAvailable = queueGroups.some((group) => group.bulkSato);
  if (bulkSatoAvailable) {
    headers.push("Bulk SATO");
    headers.push("Bulk SATO SLA Risk");
  }

  return (
    <div id="page-container">
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            {headers.map((header, idx) => (
              <th key={idx}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {queueGroups.map((group) => (
            <tr key={group.name} id={group.name}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedGroups.some((g) => group.name === g.name)}
                  onChange={(ev) => {
                    if (!ev.target.checked) {
                      const onlyCheckedGroups = selectedGroups.filter(
                        (g) => group.name !== g.name,
                      );
                      setSelectedGroups(onlyCheckedGroups);
                    } else {
                      setSelectedGroups([...selectedGroups, group]);
                    }
                  }}
                />
              </td>
              <td>{group.name}</td>
              <td>{group.totalOrders}</td>
              <td>{group.totalSlaIssues}</td>
              {bulkSatoAvailable && (
                <td>{group.bulkSato ? group.totalBulkSatoOrders : "NO"}</td>
              )}
              {bulkSatoAvailable && (
                <td>{group.bulkSato ? group.totalBulkSatoSlaIssues : "NO"}</td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <button
          onClick={() => setSelectedGroups([...queueGroups])}
          style={{ width: "175px" }}
        >
          Select All
        </button>
        <button
          onClick={() => setSelectedGroups([])}
          style={{ width: "175px" }}
        >
          Deselect All
        </button>
      </div>
      <h3>Select Print Quantity</h3>
      <div className="print-type-selection">
        <label> All </label>
        <input
          type="radio"
          name="print-quantity-all"
          className="print-radio"
          checked={printQuantity === "all"}
          onChange={() => setPrintQuantity("all")}
        />
        <label>10</label>
        <input
          type="radio"
          name="print-quantity-10"
          className="print-radio"
          checked={printQuantity === 10}
          onChange={() => setPrintQuantity(10)}
        />
        <label>50</label>
        <input
          type="radio"
          name="print-quantity-50"
          className="print-radio"
          checked={printQuantity === 50}
          onChange={() => setPrintQuantity(50)}
        />
        <label>100</label>
        <input
          type="radio"
          name="print-quantity-100"
          className="print-radio"
          checked={printQuantity === 100}
          onChange={() => setPrintQuantity(100)}
        />
        <label>500</label>
        <input
          type="radio"
          name="print-quantity-500"
          className="print-radio"
          checked={printQuantity === 500}
          onChange={() => setPrintQuantity(500)}
        />
        <label>1000</label>
        <input
          type="radio"
          name="print-quantity-1000"
          className="print-radio"
          checked={printQuantity === 1000}
          onChange={() => setPrintQuantity(1000)}
        />
        <label>2000</label>
        <input
          type="radio"
          name="print-quantity-2000"
          className="print-radio"
          checked={printQuantity === 2000}
          onChange={() => setPrintQuantity(2000)}
        />
        {!isGLF && (
          <>
            <label>5000</label>
            <input
              type="radio"
              name="print-quantity-5000"
              className="print-radio"
              checked={printQuantity === 5000}
              onChange={() => setPrintQuantity(5000)}
            />
            <label>10000</label>
            <input
              type="radio"
              name="print-quantity-10000"
              className="print-radio"
              checked={printQuantity === 10000}
              onChange={() => setPrintQuantity(10000)}
            />
          </>
        )}
      </div>
      <div>
        <h3 style={{ display: "inline-block" }}>
          Include bulk sato eligible singles?
        </h3>
        <input
          type="checkbox"
          checked={includeBulkSato.includes(1)}
          onChange={(ev) => {
            if (ev.target.checked) {
              setIncludeBulkSato([...includeBulkSato, 1]);
            } else {
              setIncludeBulkSato([...includeBulkSato.filter((i) => i !== 1)]);
            }
          }}
        />
      </div>
      <div>
        <h3 style={{ display: "inline-block" }}>
          Include bulk sato eligible doubles?
        </h3>
        <input
          type="checkbox"
          checked={includeBulkSato.includes(2)}
          onChange={(ev) => {
            if (ev.target.checked) {
              setIncludeBulkSato([...includeBulkSato, 2]);
            } else {
              setIncludeBulkSato([...includeBulkSato.filter((i) => i !== 2)]);
            }
          }}
        />
      </div>
      <div>
        <h3 style={{ display: "inline-block" }}>
          Include bulk sato eligible triples?
        </h3>
        <input
          type="checkbox"
          checked={includeBulkSato.includes(3)}
          onChange={(ev) => {
            if (ev.target.checked) {
              setIncludeBulkSato([...includeBulkSato, 3]);
            } else {
              setIncludeBulkSato([...includeBulkSato.filter((i) => i !== 3)]);
            }
          }}
        />
      </div>
      <div>
        <h3 style={{ display: "inline-block" }}>
          Include bulk sato eligible with 4 skus or more?
        </h3>
        <input
          type="checkbox"
          checked={includeBulkSato.includes(4)}
          onChange={(ev) => {
            if (ev.target.checked) {
              setIncludeBulkSato([...includeBulkSato, 4]);
            } else {
              setIncludeBulkSato([...includeBulkSato.filter((i) => i !== 4)]);
            }
          }}
        />
      </div>
      <div>
        <h3 style={{ display: "inline-block" }}>Print Handline 2?</h3>
        <input
          type="checkbox"
          checked={printHandline2Only}
          onChange={(ev) => {
            setPrintHandline2Only(!printHandline2Only);
          }}
        />
      </div>
      <div>
        <h3 style={{ display: "inline-block" }}>Print SLA Only?</h3>
        <input
          type="checkbox"
          checked={printSlaOnly}
          onChange={(ev) => {
            setPrintSLaOnly(!printSlaOnly);
          }}
        />
      </div>
      <div>
        <h3 style={{ display: "inline-block" }}>SLA (Hours Since Ordered)</h3>
        <form
          name="slaHours"
          onSubmit={refreshQueueList}
          style={{ display: "inline" }}
        >
          <input
            type="number"
            name="slaHours"
            value={slaHours}
            onChange={handleSlaChange}
            style={{ maxWidth: 100, fontSize: ".75em" }}
          ></input>
          <input
            type="submit"
            value="Update"
            style={{ fontSize: ".75em" }}
          ></input>
        </form>
      </div>
      <div>
        <button
          onClick={() => {
            if (sla == slaHours) {
              requestPrint();
            } else {
              window.alert("SLA Edited but not Updated!");
            }
          }}
          style={{ width: "175px" }}
          disabled={printing}
        >
          {printing ? "Printing" : "Print"}
        </button>
      </div>
    </div>
  );
};

export default PrintBusinessSelect;
