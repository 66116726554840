import { combineReducers, createStore } from "redux";
import { createSetterReducer, createSetter } from "../../../rdx/helpers";
import {
  actions as asyncActions,
  actionTypes as asyncTypes,
} from "../../../rdx/async";
export const actionTypes = Object.assign({}, asyncTypes, {
  SET_SKU: "SET_SKU",
  SET_ITEM: "SET_ITEM",
  UPDATE_SEARCH_INPUT: "UPDATE_SEARCH_INPUT",
  SET_INVOICE_DATA: "SET_INVOICE_DATA",
  TOGGLE_ITEM_STATUS: "TOGGLE_ITEM_STATUS",
  SET_ALL_ITEM_STATUS: "SET_ALL_ITEM_STATUS",
  SET_JIRA: "SET_JIRA",
  SET_CONTAINER: "SET_CONTAINER",
  SET_TICKET: "SET_TICKET",
});

export const actions = Object.assign({}, asyncActions, {
  updateSearchInput: (value) => {
    return {
      type: actionTypes.UPDATE_SEARCH_INPUT,
      value,
    };
  },

  setInvoiceData: (invoice, invoiceItems) => {
    return {
      type: actionTypes.SET_INVOICE_DATA,
      invoice,
      invoiceItems,
    };
  },

  toggleItemStatus: (id) => {
    return {
      type: actionTypes.TOGGLE_ITEM_STATUS,
      id,
    };
  },

  setAllItemStatus: (status) => {
    return {
      type: actionTypes.SET_ALL_ITEM_STATUS,
      status,
    };
  },

  setJira: (jira) => {
    return { type: actionTypes.SET_JIRA, jira };
  },

  setContainer: (container) => {
    return { type: actionTypes.SET_CONTAINER, container };
  },

  setTicket: (ticket) => {
    return {
      type: actionTypes.SET_TICKET,
      ticket,
    };
  },

  setSku: createSetter(actionTypes.SET_SKU, "sku"),
  setItem: createSetter(actionTypes.SET_ITEM, "setItem"),
});

export const CreditsApp = combineReducers({
  invoice: (state: any = { information: {}, items: [] }, action) => {
    if (action.type === actionTypes.SET_INVOICE_DATA)
      return { information: action.invoice, items: action.invoiceItems };
    if (action.type === actionTypes.TOGGLE_ITEM_STATUS) {
      return {
        information: state.information,
        items: state.items.map((i) => {
          if (i.id === action.id) {
            return Object.assign({}, i, { selected: !i.selected });
          }
          return i;
        }),
      };
    }
    if (action.type === actionTypes.SET_ALL_ITEM_STATUS) {
      return {
        information: state.information,
        items: state.items.map((i) =>
          Object.assign({}, i, { selected: action.status }),
        ),
      };
    }
    return state;
  },

  searchInput: (state = "", action) => {
    if (action.type === actionTypes.UPDATE_SEARCH_INPUT) return action.value;
    return state;
  },

  jira: (state = false, action) => {
    if (action.type === "SET_JIRA") return action.jira;
    return state;
  },

  container: (state = true, action) => {
    if (action.type === "SET_CONTAINER") return action.container;
    return state;
  },

  ticket: (state = {}, action) => {
    if (action.type === actionTypes.SET_TICKET) {
      return Object.assign(state, action.ticket);
    }
    return state;
  },

  sku: createSetterReducer("", actionTypes.SET_SKU, "sku"),
  item: createSetterReducer(
    { sku: "", upc: "", title: "", quantity: 0, cartId: "" },
    actionTypes.SET_ITEM,
    "setItem",
  ),
});

export const store = createStore(CreditsApp);
