import { combineReducers, createStore } from "redux";

const searchTypes = [
  {
    value: "sku",
    displayText: "SKU",
  },
  {
    value: "title",
    displayText: "Title",
  },
  {
    value: "locationId",
    displayText: "Location ID",
  },
  {
    value: "upc",
    displayText: "UPC/ISBN",
  },
];

export let actionTypes = {
  SET_ITEMS: "SET_ITEMS",
  UPDATE_SEARCH: "UPDATE_SEARCH",
  SET_SEARCH_ERROR: "SET_SEARCH_ERROR",
  SET_SEARCH_TYPE: "SET_SEARCH_TYPE",
  SET_INSERTS: "SET_INSERTS",
  SET_ADJUSTMENT_VALUE: "SET_ADJUSTMENT_VALUE",
  UPDATE_REASON_COMMENTS: "UPDATE_REASON_COMMENTS",
  SET_INSERT_ITEM: "SET_INSERT_ITEM",
  SET_MODAL_VISIBILITY: "SET_MODAL_VISIBILITY",
  SET_CURRENT_ITEM: "SET_CURRENT_ITEM",
  UPDATE_REASON_CODE: "UPDATE_REASON_CODE",
  CLEAR_ADUSTMENT_PANEL: "CLEAR_ADUSTMENT_PANEL",
  SET_PALLET_TYPE: "SET_PALLET_TYPE",
};

export let actions = {
  setItems: (items) => {
    return {
      type: actionTypes.SET_ITEMS,
      items,
    };
  },

  updateSearch: (text) => {
    return {
      type: actionTypes.UPDATE_SEARCH,
      text,
    };
  },

  setSearchError: (text) => {
    return {
      type: actionTypes.SET_SEARCH_ERROR,
      text,
    };
  },

  setSearchType: (sType) => {
    return {
      type: actionTypes.SET_SEARCH_TYPE,
      sType,
    };
  },

  setInserts: (inserts) => {
    return {
      type: actionTypes.SET_INSERTS,
      inserts,
    };
  },

  setAdjustmentValue: (value) => {
    return {
      type: actionTypes.SET_ADJUSTMENT_VALUE,
      value,
    };
  },

  updateReasonComments: (comments) => {
    return {
      type: actionTypes.UPDATE_REASON_COMMENTS,
      comments,
    };
  },

  setInsertItem: (item) => {
    return {
      type: actionTypes.SET_INSERT_ITEM,
      item,
    };
  },

  toggleModal: (visible) => {
    return {
      type: actionTypes.SET_MODAL_VISIBILITY,
      visible,
    };
  },

  setCurrentItem: (item) => {
    return {
      type: actionTypes.SET_CURRENT_ITEM,
      item,
    };
  },
  updateReasonCode: (code) => {
    return {
      type: actionTypes.UPDATE_REASON_CODE,
      code,
    };
  },
  clearAdjustmentPanel: () => {
    return { type: actionTypes.CLEAR_ADUSTMENT_PANEL };
  },
  setPalletType: (palletType) => {
    return { type: actionTypes.SET_PALLET_TYPE, palletType };
  },
};

const adjustmentPanel = combineReducers({
  adjustmentValue: (state = 0, action) => {
    if (action.type === actionTypes.SET_ADJUSTMENT_VALUE) return action.value;
    if (action.type === actionTypes.CLEAR_ADUSTMENT_PANEL) return 0;
    return state;
  },

  reasonComments: (state = "", action) => {
    if (action.type === actionTypes.CLEAR_ADUSTMENT_PANEL) return "";
    if (action.type === actionTypes.UPDATE_REASON_COMMENTS) {
      return action.comments;
    }
    return state;
  },

  reasonCode: (state = "", action) => {
    if (action.type === actionTypes.CLEAR_ADUSTMENT_PANEL) return "";
    if (action.type === actionTypes.UPDATE_REASON_CODE) return action.code;
    return state;
  },
  palletType: (state = null, action) => {
    if (action.type === actionTypes.CLEAR_ADUSTMENT_PANEL) return null;
    if (action.type === actionTypes.SET_PALLET_TYPE) return action.palletType;
    return state;
  },
});

export const adjustmentApp = combineReducers({
  totalAvailable: (state = 0, action) => {
    if (action.type === actionTypes.SET_ITEMS) {
      return action.items.reduce((p, c) => p + c.available, 0);
    }
    return state;
  },

  totalOnHand: (state = 0, action) => {
    if (action.type === actionTypes.SET_ITEMS) {
      return action.items.reduce((p, c) => p + c.onHand, 0);
    }
    return state;
  },

  totalLocations: (state = 0, action) => {
    if (action.type === actionTypes.SET_ITEMS) return action.items.length;
    return state;
  },

  items: (state = [], action) => {
    if (action.type === actionTypes.SET_ITEMS) {
      return action.items.map((item, index) =>
        Object.assign({ id: item.location_id }, item),
      );
    }
    return state;
  },

  searchInput: (state = "", action) => {
    if (action.type === actionTypes.UPDATE_SEARCH) {
      return action.text;
    }
    return state;
  },

  searchError: (state = "", action) => {
    if (action.type === actionTypes.SET_SEARCH_ERROR) {
      return action.text;
    }
    return state;
  },

  searchType: (state = "sku", action) => {
    if (action.type === actionTypes.SET_SEARCH_TYPE) return action.sType;
    return state;
  },

  showModal: (state = false, action) => {
    if (action.type === actionTypes.SET_MODAL_VISIBILITY) return action.visible;
    return state;
  },

  currentItem: (state = {}, action) => {
    if (action.type === actionTypes.SET_CURRENT_ITEM) return action.item;
    return state;
  },

  adjustmentPanel,

  searchOptions: () => searchTypes,
});

export let store = createStore(adjustmentApp);
