import React, { useState } from "react";
import fetch from "isomorphic-fetch";
import XLSX from "xlsx";
import { Header } from "../../../components/common/Header";
import Sku from "../../../../api/sku/Sku";

const BulkSkus = (props) => {
  const [sheet, setSheet] = useState(null);
  const [sheetLoaded, setSheetLoaded] = useState(false);

  const loadSheet = (ev) => {
    setSheet(ev.target.files[0]);
    setSheetLoaded(true);
  };

  const uploadSheet = async () => {
    const fileReader = new FileReader();
    fileReader.onload = async () => {
      // xlsx can luckily read basic csv files, no need to detect and have branch handling cases
      const workbook = XLSX.read(fileReader.result, { type: "binary" });
      const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]];
      const [_, ...data] = XLSX.utils.sheet_to_json(firstWorksheet, {
        header: 1,
      });

      let newSkus = data.map((ir) => {
        return new Sku({
          sku: ir[0].toString(),
          name: ir[1],
          description: ir[2],
          upc: ir[3],
          formatCode: ir[4],
          palletQty: ir[5] ? parseFloat(ir[5]) : null,
          attachWeight: ir[6] ? parseFloat(ir[6]) : null,
          eos: ir[7],
          height: ir[8] ? parseFloat(ir[8]) : null,
          width: ir[9] ? parseFloat(ir[9]) : null,
          depth: ir[10] ? parseFloat(ir[10]) : null,
          weight: ir[11] ? parseFloat(ir[11]) : null,
          clientId: ir[12],
        });
      });

      try {
        await fetch(`/api/sku/bulkSkus`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ newSkus }),
        });
        alert("Added new products successfully");
      } catch (err) {
        alert("Error adding new products");
        console.error("Error adding new products from file:", error);
      } finally {
        setSheet(null);
        setSheetLoaded(false);
        const input = document.getElementById("file");
        input.value = "";
      }
    };
    fileReader.readAsBinaryString(sheet);
  };

  return (
    <div>
      <Header url="/management">Back to Warehouse Management</Header>
      <div style={{ margin: "10px" }}>
        <div style={{ maxWidth: "500px" }}>
          <label htmlFor="file">Upload file</label>
          <input
            id="file"
            name="file"
            type="file"
            accept=".csv,text/csv,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" // .file for chrome, full type for firefox
            onChange={loadSheet}
          />
          <button onClick={uploadSheet} disabled={!sheetLoaded}>
            Upload
          </button>
        </div>
      </div>
    </div>
  );
};

export default BulkSkus;
