import React from "react";

const AccountPage: React.FC = () => {
  return (
    <div>
      <h1>Account Page</h1>
      <div className="usercard">
        <p>
          <strong>{/*window.MW_GLOBAL.user*/ "test user"}</strong>
        </p>
        <p>
          <strong>Administrator</strong>
        </p>
        <a href="/accounts/manage">
          <button>Manager User Accounts</button>
        </a>
      </div>
      <a href="/account/change">
        <button>Change Passkey</button>
      </a>
    </div>
  );
};

export default AccountPage;
