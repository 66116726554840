import { combineReducers, createStore } from "redux";
import {
  actionTypes as asyncActionTypes,
  actions as asyncActions,
} from "../../../rdx/async";
import { createSetterReducer, createSetter } from "../../../rdx/helpers";

const xtend = require("xtend");

const movesHeaders = ["Pick From", "Deliver To", "Sku", "Move Qty", "Order Id"];
const predictiveMovesHeaders = ["Pick From", "Deliver To", "Sku", "Move Qty"];
const movesKeys = ["pickup", "dropOff", "sku", "moveQuantity", "orderId"];
const predictiveMovesKeys = ["pickup", "dropOff", "sku", "quantity"];

export let actionTypes = xtend(
  {
    SET_MOVES_LIST: "SET_MOVES_LIST",
    SET_INSERTS: "SET_INSERTS",
    SET_PREDICTIVE_MOVES: "SET_PREDICTIVE_MOVES",
    SET_PREDICTIVE_INSERTS: "SET_PREDICTIVE_INSERTS",
    DELETE_PREDICTIVE_MOVE: "DELETE_PREDICTIVE_MOVE",
    ADD_IGNORE_ID: "ADD_IGNORE_ID",
    CLEAR_IGNORE_IDS: "CLEAR_IGNORE_IDS",
  },
  asyncActionTypes,
);

export let actions = xtend(
  {
    setMovesList: createSetter(actionTypes.SET_MOVES_LIST, "moves"),
    setPredictiveMovesList: createSetter(
      actionTypes.SET_PREDICTIVE_MOVES,
      "predictiveMoves",
    ),
    setInserts: createSetter(actionTypes.SET_INSERTS, "inserts"),
    setPredictiveInserts: (predictiveInserts) => {
      return {
        type: actionTypes.SET_PREDICTIVE_INSERTS,
        predictiveInserts,
      };
    },
    addIgnoreId: function (id) {
      return {
        type: actionTypes.ADD_IGNORE_ID,
        id,
      };
    },
    clearIgnoreIds: function () {
      return {
        type: actionTypes.CLEAR_IGNORE_IDS,
      };
    },
    deletePredictiveMove: function (moveIds) {
      return {
        type: actionTypes.DELETE_PREDICTIVE_MOVE,
        moveIds,
      };
    },
  },
  asyncActions,
);

export const adjustmentApp = combineReducers({
  movesList: createSetterReducer([], actionTypes.SET_MOVES_LIST, "moves"),
  showSpinner: createSetterReducer(
    false,
    actionTypes.SET_SPINNER_VISIBILITY,
    "visibility",
  ),
  error: createSetterReducer("", actionTypes.SET_ERROR, "error"),
  inserts: createSetterReducer([], actionTypes.SET_INSERTS, "inserts"),
  predictiveInserts: (state = [], action) => {
    if (action.type === actionTypes.SET_PREDICTIVE_INSERTS) {
      return action.predictiveInserts;
    }
    return state;
  },
  movesHeaders: (x) => movesHeaders,
  movesKeys: (x) => movesKeys,
  predictiveMovesHeaders: (x) => predictiveMovesHeaders,
  predictiveMovesKeys: (x) => predictiveMovesKeys,
  ignoreList: function (state: any[] = [], action) {
    if (action.type === actionTypes.ADD_IGNORE_ID) {
      let d = state.slice();
      d.push(action.id);
      return d;
    }
    if (action.type === actionTypes.CLEAR_IGNORE_IDS) {
      return [];
    }
    return state;
  },
  predictiveMovesList: function (state: any[] = [], action) {
    if (action.type === actionTypes.SET_PREDICTIVE_MOVES) {
      return action.predictiveMoves;
    }
    if (action.type === actionTypes.DELETE_PREDICTIVE_MOVE) {
      return state.filter((pm) => {
        return action.moveIds.indexOf(pm.id) === -1;
      });
    }
    return state;
  },
});

export let store = createStore(adjustmentApp);
