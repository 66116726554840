import React from "react";
import Button from "./Button";

interface FakeTableProps {
  rowClicked: (d: any, i: number) => void;
  header: string[];
  data: any[];
  keys: string[];
  inserts: any[];
  button?: {
    buttonClicked: (d: any, i: number) => void;
    buttonText: string;
  };
}

export default class FakeTable extends React.Component<FakeTableProps> {
  rowClicked(d, i) {
    this.props.rowClicked(d, i);
  }
  render() {
    let header = this.props.header.map((h) => <td key={h}>{h}</td>);
    let body = this.props.data.reduce((a, d, i) => {
      let insert = this.props.inserts.filter(
        (insertObj) => insertObj.insertId === d.id,
      )[0];
      let main = (
        <tr
          key={i}
          className={d.className}
          onClick={this.rowClicked.bind(this, d, i)}
        >
          {this.props.keys.map((k, ti) => (
            <td key={ti}>{d[k]}</td>
          ))}
          {this.props.button ? (
            <td key={i + "-button"} colSpan={this.props.header.length}>
              <Button
                onClick={this.props.button.buttonClicked.bind(this, d, i)}
              >
                {this.props.button.buttonText}
              </Button>
            </td>
          ) : null}
        </tr>
      );
      if (insert)
        return a.concat(
          main,
          <tr key={i + "-insert"} className="insert">
            <td colSpan={this.props.header.length} className={insert.className}>
              {insert.el}
            </td>
          </tr>,
        );
      return a.concat(main);
    }, []);
    return (
      <table className="fake-table">
        <thead>
          <tr>{header}</tr>
        </thead>

        <tbody>{body}</tbody>
      </table>
    );
  }
}
