import React from "react";
import { Header } from "../../components/common/Header";
import { Redirect } from "react-router-dom";
import { useCurrentClient } from "../../contexts/ClientContext";
import { Link, useRouteMatch } from "react-router-dom";

const Returns: React.FC = () => {
  const client = useCurrentClient();
  const { url } = useRouteMatch();
  if (!client) {
    return <Redirect to="/client" />;
  }
  return (
    <div>
      <Header url="../">Returns Dashboard</Header>

      <div className="content default">
        <div id="mainNav" className="block-center options">
          <Link to={url + "/credit"} style={{ width: "50%" }}>
            Credit Returns
          </Link>
          <Link to={url + "/putaway"} style={{ width: "50%" }}>
            Putaway Returns
          </Link>
          <Link to={url + "/moves"} style={{ width: "50%" }}>
            View Moves
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Returns;
