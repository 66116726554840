export interface ISku {
  sku: string;
  name: string;
  description: string;
  upc?: string;
  formatCode?: string;
  formatType?: string;
  palletQty?: number;
  attachWeight?: number;
  eos?: Boolean;
  height?: number;
  width?: number;
  depth?: number;
  weight?: number;
  clientId: number;
  clubId?: number;
  coId?: number;
  createdAt?: Date;
  updatedAt?: Date;
  satoInitials?: String;
}

export interface ISkuSearch {
  sku?: string;
  name?: string;
  description?: string;
  clientId?: number;
  upc?: string;
}

interface queryObject {
  query: string;
  params: string[];
}

export class SkuSearch implements ISkuSearch {
  sku?: string;
  name?: string;
  description?: string;
  clientId?: number;
  upc?: string;

  constructor(SkuSearch: ISkuSearch) {
    if (SkuSearch) {
      Object.assign(this, SkuSearch);
    }
  }

  toQuery(): queryObject {
    let filters = ``,
      params = [],
      i = 1;

    for (let v in this) {
      if (!!this[v]) {
        if (typeof this[v] === "number") {
          params.push(this[v]);
          filters += `AND ${v.replace(/([A-Z])/g, function ($1) {
            return "_" + $1.toLowerCase();
          })} = $${i++} `;
        } else {
          params.push(`%${this[v]}%`);
          filters += `AND ${v.replace(/([A-Z])/g, function ($1) {
            return "_" + $1.toLowerCase();
          })} ILIKE $${i++} `;
        }
      }
    }

    const query = `

      SELECT
        sku, name, description, height, width, depth, weight,
        upc, format_code AS "formatCode", pallet_qty AS "palletQty",
        attach_weight AS "attachWeight", eos,
        client_id AS "clientId", club_id AS "clubId", co_id AS "coId",
        created_at AS "createdAt",
        updated_at AS "updatedAt"
      FROM
        sku
      WHERE
        deleted_at IS NULL ${filters};

      `;

    return { query, params };
  }
}

export default class Sku implements ISku {
  sku: string;
  name: string;
  description: string;
  upc?: string;
  formatCode?: string;
  formatType?: string;
  palletQty?: number;
  attachWeight?: number;
  eos?: Boolean;
  height?: number;
  width?: number;
  depth?: number;
  weight?: number;
  clientId: number;
  clubId?: number;
  coId?: number;
  createdAt?: Date;
  updatedAt?: Date;
  satoInitials?: string;

  constructor(Sku: ISku) {
    if (Sku) {
      Object.assign(this, Sku);
    }
  }

  toJSON(): Object {
    return {
      sku: this.sku,
      name: this.name,
      description: this.description,
      upc: this.upc,
      formatCode: this.formatCode,
      formatType: this.formatType,
      palletQty: this.palletQty,
      attachWeight: this.attachWeight,
      eos: this.eos,
      height: this.height,
      width: this.width,
      depth: this.depth,
      weight: this.weight,
      clientId: this.clientId,
      clubId: this.clubId,
      coId: this.coId,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      satoInitials: this.satoInitials,
    };
  }
}
