import React from "react";
import ReasonCommit from "../../../components/common/reason-commit";
import { SimpleSelect } from "../../../components/common/form-components";
export default function AdjustmentItem(props) {
  return (
    <div>
      <div style={{ width: "40%", float: "left" }}>
        {props.showPalletOption ? (
          <SimpleSelect
            options={[
              {
                value: props.initialPalletType,
                displayText:
                  "Current Pallet Type : " +
                  (props.initialPalletType === ""
                    ? "<NONE>"
                    : props.initialPalletType),
              },
              {
                value: "",
                displayText: "<NONE>",
              },
              {
                value: "B",
                displayText: "B",
              },
              {
                value: "I",
                displayText: "I",
              },
              {
                value: "P",
                displayText: "P",
              },
              {
                value: "R",
                displayText: "R",
              },
            ]}
            onChange={props.handlePalletType}
          />
        ) : (
          ""
        )}
        <h3>Adjustment : {props.adjustmentValue}</h3>

        <button onClick={() => props.adjustQuantity(-1)}>Decrease</button>
        <button onClick={() => props.adjustQuantity(1)}>Increase</button>

        <input
          type="number"
          value={props.adjustmentValue}
          onChange={props.updateSelectedQuantity}
          style={{ marginLeft: "5px", float: "right", width: "55%" }}
        />
      </div>

      <ReasonCommit
        reasonCodeChange={props.reasonCodeChange}
        onTextChange={props.onReasonTextChange}
        commentsText={props.reasonComments}
        commit={props.adjustInventory}
        showCommitButton={props.showCommitButton}
      />
    </div>
  );
}
