import { Header } from "../../../components/common/Header";
import FakeTable from "../../../components/common/FakeTable";
import React from "react";
import reqwest from "reqwest";
import { actions, store } from "../rdx/credit";

function changeWrapper(changeFn, evt) {
  changeFn(evt.target.value, evt);
}

function enterWrapper(fn, evt) {
  if (evt.key === "Enter") return fn();
}

export default class ReturnCredit extends React.Component<{}, any> {
  constructor(props) {
    super(props);
    this.state = store.getState();
    store.subscribe(() => this.setState(store.getState()));
  }

  search() {
    let inv;
    if (this.state.searchInput.length > 29)
      inv = this.extractInvoiceNumber(this.state.searchInput);
    else inv = this.state.searchInput;
    console.log(inv);
    reqwest({
      url: `/api/invoice/${inv}`,
      method: "GET",
      type: "json",
      headers: {
        "X-myworld-client": /*MW_GLOBAL.clientId*/ 6,
      },
      contentType: "application/json",
    })
      .then((res) => {
        console.log("my response", res);
        if (
          /*(MW_GLOBAL.clientId === '90')*/ false ||
          /* (MW_GLOBAL.clientId === '94') */ false
        ) {
          if (res[1].length !== 0) {
            store.dispatch(
              actions.setInvoiceData(
                res[0][0],
                res[1].map((i, idx) =>
                  Object.assign({ selected: false, id: idx }, i),
                ),
              ),
            );
          } else {
            store.dispatch(actions.setJira(true));
            store.dispatch(actions.setContainer(false));
          }
        } else {
          if (res.length === 0) {
            store.dispatch(actions.setJira(true));
            store.dispatch(actions.setContainer(false));
          } else {
            store.dispatch(
              actions.setInvoiceData(
                res,
                res.map((i, idx) =>
                  Object.assign({ selected: false, id: idx }, i),
                ),
              ),
            );
          }
        }
      })
      .catch(console.error);
  }

  tabelify(item) {
    if (item.selected)
      return Object.assign({ className: "selected-return" }, item);
    return item;
  }

  toggleRow(item) {
    console.log(item);
    return store.dispatch(actions.toggleItemStatus(item.id));
  }

  selectAll() {
    store.dispatch(actions.setAllItemStatus(true));
  }

  deselectAll() {
    store.dispatch(actions.setAllItemStatus(false));
  }

  creditReturns() {
    const selected = this.state.invoice.items.filter(
      (i) => i.selected === true,
    );
    if (selected.length === 0) return alert("Nothing selected");
    reqwest({
      url: "/api/returns/credit",
      method: "POST",
      type: "json",
      headers: {
        "X-myworld-client": /*MW_GLOBAL.clientId*/ 6,
      },
      contentType: "application/json",
      data: JSON.stringify({
        partial: selected !== this.state.invoice.items.length,
        invoiceNumber:
          this.state.invoice.information.invoice_number ||
          selected[0].invoice_number,
        items: selected,
      }),
    })
      .then((r) => {
        alert("Credited!");
        store.dispatch(actions.setInvoiceData({}, []));
        store.dispatch(
          actions.setItem({
            sku: "",
            upc: "",
            title: "",
            quantity: 0,
            cartId: "",
          }),
        );
        store.dispatch(actions.updateSearchInput(""));
      })
      .catch((err) => {
        alert(err);
      });
  }

  extractInvoiceNumber(barcode) {
    return barcode.slice(22, 29).replace(/^0+/, "");
  }

  submitTicket(e) {
    reqwest({
      url: "/api/returns/credit/jira",
      method: "POST",
      type: "json",
      headers: {
        "X-myworld-client": /*MW_GLOBAL.clientId*/ 6,
      },
      contentType: "application/json",
      data: JSON.stringify(this.state.ticket),
    })
      .then((r) => {
        alert("Submitted for manual review");
        store.dispatch(
          actions.setTicket({
            invoice: "",
            date: "",
            accountnum: "",
            salesordernum: "",
            barcode: "",
            name: "",
            reason: "",
            skus: "",
          }),
        );
        store.dispatch(actions.setInvoiceData({}, []));
        store.dispatch(
          actions.setItem({
            sku: "",
            upc: "",
            title: "",
            quantity: 0,
            cartId: "",
          }),
        );
        store.dispatch(actions.updateSearchInput(""));
        store.dispatch(actions.setJira(false));
        store.dispatch(actions.setContainer(true));
      })
      .catch((err) => {
        alert(err);
      });
  }

  callTicket(e) {
    store.dispatch(actions.setJira(true));
    store.dispatch(actions.setContainer(false));
  }

  render() {
    let lbl = "";
    let jiraContainer = null;
    let mainContainer = null;
    if (/*MW_GLOBAL.clientId === '6'*/ true)
      lbl =
        "Input invoice number(ex 8008379 or 20654634) or barcode, or customer acccount id (ex botm635 or 0045314993)";
    else
      lbl =
        "Input invoice number(ex 20654634) or customer account id (ex 11937669001)";

    if (this.state.jira) {
      jiraContainer = (
        <div>
          <p style={{ textAlign: "center" }}>
            This invoice could not be found. Please submit a ticket for manual
            review.
          </p>
          Invoice Number:{" "}
          <input
            value={this.state.ticket.invoice}
            type="text"
            name="invoice"
            onChange={changeWrapper.bind(null, (val) =>
              store.dispatch(actions.setTicket({ invoice: val })),
            )}
          />
          Invoice Date:{" "}
          <input
            value={this.state.ticket.date}
            type="text"
            name="date"
            onChange={changeWrapper.bind(null, (val) =>
              store.dispatch(actions.setTicket({ date: val })),
            )}
          />
          Account Number:{" "}
          <input
            value={this.state.ticket.accountnum}
            type="text"
            name="account"
            onChange={changeWrapper.bind(null, (val) =>
              store.dispatch(actions.setTicket({ accountnum: val })),
            )}
          />
          Sales Order Number:{" "}
          <input
            value={this.state.ticket.salesordernum}
            type="text"
            name="salesordernum"
            onChange={changeWrapper.bind(null, (val) =>
              store.dispatch(actions.setTicket({ salesordernum: val })),
            )}
          />
          Barcode:{" "}
          <input
            value={this.state.ticket.barcode}
            type="text"
            name="barcode"
            onChange={changeWrapper.bind(null, (val) =>
              store.dispatch(actions.setTicket({ barcode: val })),
            )}
          />
          Name:{" "}
          <input
            value={this.state.ticket.name}
            type="text"
            name="name"
            onChange={changeWrapper.bind(null, (val) =>
              store.dispatch(actions.setTicket({ name: val })),
            )}
          />
          Skus Returned:{" "}
          <input
            value={this.state.ticket.skus}
            type="text"
            name="skus"
            onChange={changeWrapper.bind(null, (val) =>
              store.dispatch(actions.setTicket({ skus: val })),
            )}
          />
          Reason for Return:{" "}
          <input
            value={this.state.ticket.reason}
            type="text"
            name="reason"
            onChange={changeWrapper.bind(null, (val) =>
              store.dispatch(actions.setTicket({ reason: val })),
            )}
          />
          <button onClick={this.submitTicket.bind(this)}>Submit Ticket</button>
          <br></br>
          <div style={{ marginTop: "75px" }}>
            <a
              href={
                "/client/" +
                /*this.props.params.client*/ "bookspan" +
                "/returns/credit"
              }
            >
              Back
            </a>
          </div>
        </div>
      );
    }

    if (this.state.container) {
      mainContainer = (
        <div>
          <label htmlFor={"search"}>{lbl}</label>
          <input
            ref="searchRef"
            value={this.state.searchInput}
            type="text"
            name="search"
            onChange={changeWrapper.bind(null, (val) =>
              store.dispatch(actions.updateSearchInput(val)),
            )}
            onKeyUp={enterWrapper.bind(null, () => this.search())}
          />

          <button onClick={() => this.search()}>Search</button>
          <FakeTable
            header={[
              "Invoice Number",
              "Invoice Date",
              "Quantity",
              "Sku",
              "Title",
            ]}
            data={this.state.invoice.items.map(this.tabelify)}
            keys={[
              "invoice_number",
              "invoice_date",
              "quantity",
              "sku",
              "short_title",
            ]}
            inserts={[]}
            rowClicked={this.toggleRow.bind(this)}
          />
          <button style={{ margin: "0 5px" }} onClick={this.selectAll}>
            Select All
          </button>
          <button style={{ margin: "0 5px" }} onClick={this.deselectAll}>
            Deselect All
          </button>
          <button
            style={{ background: "green", margin: "0 5px" }}
            onClick={this.creditReturns.bind(this)}
          >
            Credit Greens
          </button>
          <button
            style={{ background: "red" }}
            onClick={() => {
              store.dispatch(actions.setInvoiceData({}, []));
              store.dispatch(
                actions.setItem({
                  sku: "",
                  upc: "",
                  title: "",
                  quantity: 0,
                  cartId: "",
                }),
              );
            }}
          >
            Cancel
          </button>
          <button
            style={{ margin: "0 5px" }}
            onClick={() => {
              store.dispatch(actions.setJira(true));
              store.dispatch(actions.setContainer(false));
            }}
          >
            Create Ticket
          </button>
        </div>
      );
    }

    return (
      <div>
        <Header url="./">Returns - Credit</Header>
        {mainContainer}
        {jiraContainer}
      </div>
    );
  }
}
