import React from "react";
import { Header } from "../../../components/common/Header";
import { Link, useRouteMatch } from "react-router-dom";

interface HomeProps {
  blockColor: string;
  clientName: string;
}

const Home: React.FC<HomeProps> = (props) => {
  const { url } = useRouteMatch();

  return (
    <div>
      <Header url="/">
        <span style={{ color: props.blockColor }}>{props.clientName}</span>{" "}
        Totally Awesome Warehouse
      </Header>

      <div id="mainNav" className="content">
        <p>Please select a task to start</p>

        <Link
          to={url + "/receiving"}
          style={{ backgroundColor: props.blockColor }}
        >
          Receiving
        </Link>
        <Link
          to={url + "/returns"}
          style={{ backgroundColor: props.blockColor }}
        >
          Returning
        </Link>
        <Link
          to={url + "/inventory"}
          style={{ backgroundColor: props.blockColor }}
        >
          Inventory
        </Link>
        <Link
          to={url + "/moving"}
          style={{ backgroundColor: props.blockColor }}
        >
          Moving
        </Link>
        <Link
          to={url + "/lookup"}
          style={{ backgroundColor: props.blockColor }}
        >
          Lookup
        </Link>
        <Link
          to={url + "/weight"}
          style={{ backgroundColor: props.blockColor }}
        >
          Weight
        </Link>
      </div>
    </div>
  );
};

export default Home;
