import React from "react";
import { useState, useEffect } from "react";
import {
  useRouteMatch,
  Redirect,
  useParams,
  useHistory,
} from "react-router-dom";

const PrintBusinessSelect: React.FC = () => {
  const [businesses, setBusinesses] = useState(null);

  const { clientId } = useParams<{ clientId }>();
  const refreshBusinessList = async () => {
    const res = await fetch(`/api/print/${clientId}`, {
      credentials: "include",
    });
    const businesses = await res.json();
    setBusinesses(businesses);
  };

  useEffect(() => {
    refreshBusinessList();
  }, []);

  let { url } = useRouteMatch();

  const history = useHistory();

  if (!businesses) {
    return <div>Loading...</div>;
  }
  if (businesses?.length === 1) {
    const business = businesses[0];
    return <Redirect to={`${url}/${business.id}`} />;
  }
  const headers = ["Business Name", "Total Orders"];

  const machineableAvailable = businesses.some(
    (business) => business.machineable,
  );
  const bulkSatoAvailable = businesses.some((business) => business.bulkSato);

  if (machineableAvailable) {
    headers.push("Machineable");
  }
  if (bulkSatoAvailable) {
    headers.push("Bulk SATO");
  }

  return (
    <div id="page-container">
      <table style={{ width: "100%", cursor: "pointer" }}>
        <thead>
          <tr>
            {headers.map((header) => (
              <th>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {businesses.map((business) => (
            <tr
              id={business.id}
              onClick={() => history.push(`${url}/${business.id}`)}
            >
              <td>{business.name}</td>
              <td>{business.totalOrders}</td>
              {machineableAvailable && (
                <td>
                  {business.machineable
                    ? business.totalMachineableOrders
                    : "NO"}
                </td>
              )}
              {bulkSatoAvailable && (
                <td>
                  {business.bulkSato ? business.totalBulkSatoOrders : "NO"}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PrintBusinessSelect;
