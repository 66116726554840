import React from "react";
import fetch from "isomorphic-fetch";
import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";

const LoginPage: React.FC = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useContext(UserContext);
  const [error, setError] = useState(null);
  const history = useHistory();

  const onLogin = async () => {
    try {
      await login(username, password);
      history.push("/client");
    } catch (err) {
      setError(err.message);
    }
  };
  return (
    <div id="page-container">
      <div id="login-page">
        <form
          onSubmit={(event) => {
            event.preventDefault();
            onLogin();
          }}
        >
          <label>User</label>
          <input
            type="text"
            name="username"
            id="username"
            placeholder="user"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
          />
          <label>6 Digit Passcode</label>
          <input
            type="number"
            name="password"
            id="password"
            placeholder="12345"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <button type="submit">Login</button>
        </form>
      </div>
      <div id="error-page">
        <h1>{error}</h1>
      </div>
    </div>
  );
};

export default LoginPage;
