import React from "react";
import { useState, useEffect } from "react";
import { Redirect, useRouteMatch, useHistory } from "react-router-dom";

const PrintClientSelect: React.FC = () => {
  const [clients, setClients] = useState(null);
  const refreshClientList = async () => {
    const res = await fetch("/api/print", {
      credentials: "include",
    });
    const clients = await res.json();
    setClients(clients);
  };

  useEffect(() => {
    refreshClientList();
  }, []);

  const { url } = useRouteMatch();

  const history = useHistory();

  if (!clients) {
    return <div>Loading...</div>;
  }
  if (clients?.length === 1) {
    const client = clients[0];
    return <Redirect to={`${url}/${client.id}`} />;
  }
  return (
    <div id="page-container">
      <table style={{ width: "100%", cursor: "pointer" }}>
        <thead>
          <tr>
            <th>Client Name</th>
            <th>Total Orders</th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client) => (
            <tr
              id={client.id}
              onClick={() => history.push(`${url}/${client.id}`)}
            >
              <td>{client.name}</td>
              <td>{client.totalOrders}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PrintClientSelect;
