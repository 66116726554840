import React from "react";
import { useState } from "react";
import reqwest from "reqwest";
import { Header } from "../../../components/common/Header";
import Linput from "../../../components/common/Linput";
import { useCurrentClient } from "../../../contexts/ClientContext";

interface LookupOrderState {
  searchInput: string;
  order: any;
}

const LookupOrder: React.FC = (props) => {
  const [searchInput, setSearchInput] = useState("");
  const [shipment, setShipment] = useState(null);
  const [notice, setNotice] = useState(null);
  const currentClient = useCurrentClient();

  const lookupOrder = async () => {
    const response = await fetch(
      `/api/shipment?clientId=${currentClient.id}&search=${searchInput}`,
      {
        credentials: "include",
      },
    );
    if (response.status !== 200) {
      setNotice(await response.text());
    } else {
      const shipment = await response.json();
      setShipment(shipment);
      setNotice(null);
    }
  };
  return (
    <div>
      <Header url="./">Order Lookup Tool</Header>
      <Linput
        name="enter-invoice-number"
        value={searchInput}
        onChange={(v) => setSearchInput(v)}
        onEnter={() => lookupOrder()}
      />
      <button onClick={() => lookupOrder()}>Find Order</button>
      {shipment ? (
        <div>
          Customer Account ID: <span>{shipment.customerAccountId}</span>
          <br />
          External ID: <span>{shipment.externalId}</span>
          <br />
          ID: <span>{shipment.id}</span>
          <br />
          Barcode: <span>{shipment.barcode}</span>
          <br />
          Shipping:
          <br />
          <span>{shipment.address.addressee}</span>
          <br />
          <span>{shipment.address.street1}</span>
          <br />
          <span>{shipment.address.city}</span>,{" "}
          <span>{shipment.address.state}</span>{" "}
          <span>{shipment.address.zipcode}</span>
          <br />
          Printed: {!!shipment.printedAt ? "YES" : "NO"} <br />
          Shipped: {!!shipment.manifestedAt ? "YES" : "NO"} <br />
          Receive Date:{" "}
          <span>
            {shipment.receivedAt
              ? new Date(shipment.receivedAt).toString()
              : ""}
          </span>
          <br />
          Last Printed:{" "}
          <span>
            {shipment.printedAt ? new Date(shipment.printedAt).toString() : ""}
          </span>
          <br />
          Manifest Date:{" "}
          <span>
            {shipment.manifestedAt
              ? new Date(shipment.manifestedAt).toString()
              : ""}
          </span>
          <br />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default LookupOrder;
