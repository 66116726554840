import React from "react";

const styles = {
  container: {
    width: "100%",
    height: "100%",
    margin: "0 auto",
    paddingTop: "30",
    position: "fixed" as "fixed",
    top: 0,
    left: 0,
    background: "rgba(0,0,0,.9)",
    zIndex: 1090,
  },

  image: {
    position: "absolute" as "absolute",
    left: "50%",
    top: "50%",
    width: "auto",
    height: "auto",
  },
};

export default function Spinner(props) {
  if (props.show === false) return <span></span>;
  return (
    <div style={styles.container}>
      <img style={styles.image} src="/images/loading.gif" alt="loading" />
    </div>
  );
}
