import React from "react";
import InputLabel from "../../../components/common/InputLabel";
import Note from "../../../components/common/note";

export default (props) => {
  return (
    <div id="detailsDiv">
      <Note>*Required fields in red</Note>

      <form className="detailsForm">
        <div>
          <InputLabel
            wrapperClass="ir-title-wrapper"
            required={false}
            updateItem={props.updateItem}
            value={props.item.title}
            id="title"
          >
            Title:
          </InputLabel>

          <InputLabel
            wrapperClass="input-receiving-wrapper"
            disabled={true}
            updateItem={props.updateItem}
            value={props.item.sku}
            id="sku"
          >
            Sku:
          </InputLabel>

          <InputLabel
            required={false}
            updateItem={props.updateItem}
            value={props.item.upc}
            id="upc"
          >
            UPC:
          </InputLabel>

          <InputLabel
            updateItem={props.updateItem}
            value={props.item.length}
            id="length"
          >
            Length:
          </InputLabel>

          <InputLabel
            updateItem={props.updateItem}
            value={props.item.width}
            id="width"
          >
            Width:
          </InputLabel>

          <InputLabel
            updateItem={props.updateItem}
            value={props.item.depth}
            id="depth"
          >
            Depth:
          </InputLabel>

          <InputLabel
            updateItem={props.updateItem}
            value={props.item.weight}
            id="weight"
          >
            Weight (oz):
          </InputLabel>

          <InputLabel
            updateItem={props.updateItem}
            value={props.item.attachWeight}
            id="attachWeight"
          >
            ATTACHED (oz):
          </InputLabel>

          <InputLabel
            updateItem={props.updateItem}
            value={props.item.formatCode}
            id="formatCode"
          >
            Format:
          </InputLabel>

          <InputLabel
            updateItem={props.updateItem}
            value={props.item.palletQty}
            id="palletQty"
          >
            Pallet Qty:
          </InputLabel>

          <InputLabel
            updateItem={props.updateItem}
            value={props.item.clientId}
            id="clientId"
          >
            Client Id:
          </InputLabel>

          <InputLabel
            updateItem={props.updateItem}
            value={props.item.coId}
            id="coId"
          >
            CO ID:
          </InputLabel>

          <InputLabel
            updateItem={props.updateItem}
            value={props.item.clubId}
            id="clubId"
          >
            Club Id:
          </InputLabel>

          <InputLabel
            updateItem={props.updateItem}
            value={props.item.obsoleteFlag}
            id="obsoleteFlag"
          >
            Obsolete (Y/N):
          </InputLabel>

          <InputLabel
            updateItem={props.updateItem}
            value={props.item.releaseDate}
            id="releaseDate"
          >
            Release Date:
          </InputLabel>
        </div>
      </form>
    </div>
  );
};
