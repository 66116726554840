import React from "react";
import { useState } from "react";
import { Header } from "../../../components/common/Header";

const CREATE_TITLE_DEFAULT = "Create DHL Manifest";

const DOMESTIC = "domestic";
const INTERNATIONAL = "international";

let style = {
  mainDiv: {
    textAlign: "center" as "center",
  },
  input: {
    float: "none",
  },
};

const SendDHL: React.FC = (props) => {
  const [notice, setNotice] = useState(null);
  const [shipmentId, setShipmentId] = useState("");
  const [createTitle, setCreateTitle] = useState(CREATE_TITLE_DEFAULT);
  const [destination, setDestination] = useState(INTERNATIONAL);
  const [resetEnabled, setResetEnabled] = useState(false);

  const sendCreateManifest = async () => {
    setCreateTitle("Loading...");
    try {
      const response = await fetch(`/api/dhl/createManifest`, {
        credentials: "include",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ destination }),
      });

      if (!response.ok) {
        throw new Error(await response.text());
      }

      const { shipmentId: dhlShipmentId } = await response.json();
      setShipmentId(dhlShipmentId);
      setNotice("Check email for link to pdf download or view below");
      setCreateTitle("Completed");
    } catch (e) {
      setCreateTitle("Error");
      setNotice(e.toString());
    }
  };

  const handleChange = (event) => {
    setDestination(event.target.value);
  };

  const mainDisable = createTitle !== CREATE_TITLE_DEFAULT;

  return (
    <div style={style.mainDiv}>
      <Header url="./"> Create and Download DHL File</Header>
      <div>
        <button onClick={() => sendCreateManifest()} disabled={mainDisable}>
          {createTitle}
        </button>
        <select
          title={
            mainDisable
              ? "Reset Before Switching Destination"
              : "DHL Destination"
          }
          defaultValue={destination}
          onChange={handleChange}
          disabled={mainDisable}
        >
          <option value={DOMESTIC}>Domestic</option>
          <option value={INTERNATIONAL}>International</option>
        </select>
      </div>
      {notice && (
        <div>
          <p>{notice}</p>
          <div>
            {shipmentId && (
              <a
                href={`https://taw.newworlddirect.com/api/dhl/pdfs/${shipmentId}`}
                target="_blank"
                onClick={() => setResetEnabled(true)}
              >
                Download DHL Manifest
              </a>
            )}
          </div>
        </div>
      )}
      {resetEnabled && (
        <div style={{ paddingTop: "1.5em" }}>
          <button
            onClick={() => {
              setNotice(null);
              setShipmentId("");
              setCreateTitle(CREATE_TITLE_DEFAULT);
              setDestination(INTERNATIONAL);
              setResetEnabled(false);
            }}
          >
            Reset DHL Manifest Form
          </button>
        </div>
      )}
    </div>
  );
};

export default SendDHL;
