import React from "react";
import { useState } from "react";
import fetch from "isomorphic-fetch";
import { Header } from "../../../components/common/Header";
import Spinner from "../../../components/common/Spinner";
import Linput from "../../../components/common/Linput";
import ItemDetails from "../components/ItemDetails";
import Locations from "../components/locations";
import { withCurrentClient } from "../../../contexts/ClientContext";

var styles = {
  itemContainer: {
    textAlign: "center" as "center",
  },
  button: {
    marginLeft: 20,
  },
};

const defaultSkuObject = {
  palletQty: 0,
};

const InputInventory = (props) => {
  const [sku, setSku] = useState("");
  const [skuObject, setSkuObject] = useState(defaultSkuObject);
  const [quantity, setQuantity] = useState("");
  const [dropOff, setDropOff] = useState("");
  const [existingLocations, setExistingLocations] = useState([]);
  const [emptyLocations, setEmptyLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [displayItemContainer, setDisplayItemContainer] = useState(false);

  const sortLocations = (locations) => {
    locations.sort((a, b) => {
      if (a.type === b.type) {
        if (a.id < b.id) {
          return -1;
        } else {
          return 1;
        }
      }
      if (a.type === "VNAP" && b.type !== "VNAP") return -1;
      if (b.type === "VNAP" && a.type !== "VNAP") return 1;
      if (a.type === "STDP" && b.type !== "STDP") return -1;
      if (b.type === "STDP" && a.type !== "STDP") return 1;
      if (a.type === "STDP CABINET" && b.type !== "STDP CABINET") return -1;
      if (b.type === "STDP CABINET" && a.type !== "STDP CABINET") return 1;
      if (a.type === "BULK" && b.type !== "BULK") return -1;
      if (b.type === "BULK" && a.type !== "BULK") return 1;
    });
  };

  const getEmpties = async () => {
    const res = await fetch(
      `/api/location?unassigned=true&empty=true&status=A&type=VNAP,STDP,BULK`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      },
    );
    const locations = await res.json();
    sortLocations(locations);
    setEmptyLocations(locations);
  };

  const getExisting = async (sku) => {
    const res = await fetch(
      `/api/location?sku=${sku}&status=A&type=VNAP,STDP,BULK,FLOW`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      },
    );
    const locations = await res.json();
    sortLocations(locations);
    setExistingLocations(locations);
  };

  const getSku = async () => {
    try {
      setIsLoading(true);
      if (!sku.length) throw new Error("Invalid sku");
      const data = await fetch(`/api/sku/${sku}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      const skuData = await data.json();
      await getEmpties();
      await getExisting(sku);
      setSkuObject(skuData);
      setDisplayItemContainer(true);
    } catch (err) {
      console.error(err);
      alert(`Error: sku ${sku} not found`);
    } finally {
      setIsLoading(false);
    }
  };

  const clear = () => {
    setQuantity("");
    setDropOff("");
    setIsLoading(false);
  };

  const createMove = async () => {
    if (!(sku && skuObject && dropOff && quantity)) {
      throw new Error("invalid input");
    }
    try {
      setIsLoading(true);
      let location = await fetch(`/api/location/${dropOff}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      location = await location.json();
      const query = `
        The chosen destination (ID = ${dropOff}) is currently disabled.
        Completing this move will make the inventory inaccessible.
        Are you sure you want to make a move to this location?
      `;
      const answer =
        location.status === "D" ? confirm(query) : confirm("Are you sure?");
      if (answer) {
        if (!["", sku].some((s) => s === location.sku))
          throw new Error("Invalid drop off location!");
        const res = await fetch(`/api/move`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            pickup: null,
            dropOff,
            moveQuantity: quantity,
            sku,
            isNormal: true,
            status: "PENDING",
            type: "RECEIVE",
          }),
        });
        if (!res.ok) throw new Error("Error creating move!");
        clear();
        await getEmpties();
        await getExisting(sku);
        alert("Move successfully created");
      }
    } catch (err) {
      console.error(err);
      alert(`${err}`);
    } finally {
      setIsLoading(false);
    }
  };

  const ItemContainer = (
    <div style={styles.itemContainer}>
      <ItemDetails
        item={skuObject}
        updateItem={() => null}
        disablePalletType={
          /*this.props.params.client.toUpperCase() === 'BOOKSPAN'*/ true
        }
        quantity={quantity}
        setQuantity={setQuantity}
        dropOff={dropOff}
        setDropOff={setDropOff}
      />
      <br />
      <button
        style={{ margin: "5px", background: "green", marginLeft: "40%" }}
        onClick={async () => await createMove()}
      >
        Save
      </button>
      <button
        style={{ margin: "5px", background: "red" }}
        onClick={() => clear()}
      >
        Clear
      </button>
      <Locations
        locations={{ current: existingLocations, empty: emptyLocations }}
        updateDropOffId={(id) => setDropOff(id)}
      />
    </div>
  );

  var Container = (
    <div>
      <Linput
        label={"Search by Sku"}
        name="enter-sku"
        value={sku}
        onChange={(v) => setSku(v)}
        onEnter={async () => await getSku()}
      />
      <button onClick={async () => await getSku()}>Search</button>

      {displayItemContainer ? ItemContainer : null}
    </div>
  );

  return (
    <div>
      <Header url="./">Receivers Dashboard - Input</Header>

      {Container}
      <Spinner show={isLoading} />
    </div>
  );
};

export default withCurrentClient(InputInventory);
