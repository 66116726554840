import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import fetch from "isomorphic-fetch";
import { Header } from "../../../../components/common/Header";

const InvoiceTemplates = () => {
  const [templates, setTemplates] = useState([]);

  const getTemplates = async () => {
    let res = await fetch(`/api/template`, {
      credentials: "include",
    });
    res = await res.json();
    setTemplates(res);
  };

  useEffect(() => {
    getTemplates();
  }, []);

  const renderTemplates = (templates) => {
    return templates.map(({ name, id }) => (
      <li key={id}>
        <Link to={`/management/invoice-templates/${id}`}>
          {id} {name}
        </Link>
      </li>
    ));
  };
  return (
    <React.Fragment>
      <Header url="/management">Back to Warehouse Management</Header>
      <ul
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <h1>Templates</h1>
        {renderTemplates(templates)}
      </ul>
    </React.Fragment>
  );
};

export default InvoiceTemplates;
