import React from "react";
import InputLabel from "../../../components/common/InputLabel";
import Note from "../../../components/common/note";

export default class ItemDetails extends React.Component<any> {
  render() {
    return (
      <div id="detailsDiv">
        <Note>*Required fields in red</Note>

        <form className="detailsForm">
          <div>
            <InputLabel
              wrapperClass="ir-title-wrapper"
              required={false}
              disabled={true}
              updateItem={() => null}
              value={this.props.item.name}
              id="title"
            >
              Title:
            </InputLabel>

            <InputLabel
              wrapperClass="input-receiving-wrapper"
              required={false}
              disabled={true}
              updateItem={() => null}
              value={this.props.item.sku}
              id="sku"
            >
              Sku:
            </InputLabel>

            <InputLabel
              required={false}
              disabled={true}
              updateItem={() => null}
              value={this.props.item.upc}
              id="upc"
            >
              UPC:
            </InputLabel>

            <InputLabel
              required={false}
              updateItem={() => null}
              disabled={true}
              value={this.props.item.height}
              id="length"
            >
              Length:
            </InputLabel>

            <InputLabel
              required={false}
              updateItem={() => null}
              disabled={true}
              value={this.props.item.width}
              id="width"
            >
              Width:
            </InputLabel>

            <InputLabel
              required={false}
              updateItem={() => null}
              disabled={true}
              value={this.props.item.depth}
              id="depth"
            >
              Depth:
            </InputLabel>

            <InputLabel
              required={false}
              updateItem={() => null}
              disabled={true}
              value={this.props.item.weight}
              id="weight"
            >
              Weight (oz):
            </InputLabel>

            <InputLabel
              required={false}
              updateItem={() => null}
              disabled={true}
              value={this.props.item.attachWeight}
              id="attachWeight"
            >
              ATTACHED (oz):
            </InputLabel>

            <InputLabel
              required={false}
              updateItem={() => null}
              disabled={true}
              value={this.props.item.formatCode}
              id="formatCode"
            >
              Format:
            </InputLabel>

            <InputLabel
              required={false}
              updateItem={() => null}
              disabled={true}
              value={this.props.item.palletQty}
              id="palletQty"
            >
              Pallet Qty:
            </InputLabel>

            <InputLabel
              updateItem={() => (e) => this.props.setDropOff(e.target.value)}
              value={this.props.dropOff}
              id="dropOff"
            >
              DropOff:
            </InputLabel>
            <InputLabel
              updateItem={() => (e) => this.props.setQuantity(e.target.value)}
              value={this.props.quantity}
              id="quantity"
            >
              Quantity:
            </InputLabel>
          </div>
        </form>
      </div>
    );
  }
}
