import React from "react";

function changeWrapper(changeFn, evt) {
  changeFn(evt.target.value, evt);
}

function enterWrapper(fn, evt) {
  if (evt.key === "Enter") return fn();
}

export default (props) => {
  return (
    <div>
      <label htmlFor={props.name}>{props.label}</label>
      <input
        value={props.value}
        type="text"
        name={props.name}
        onChange={changeWrapper.bind(null, props.onChange)}
        onKeyUp={enterWrapper.bind(null, props.onEnter)}
      />
    </div>
  );
};
