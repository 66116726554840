import React, { useState, useEffect } from "react";

import { Header } from "../../../components/common/Header";
import QueuesTable from "../components/QueuesTable";

type QueueUpdates = {
  queuesToAdd: string[];
  queuesToRemove: string[];
};

const tableStyle: React.CSSProperties = {
  flex: 1,
  border: "5px solid black",
  borderRadius: "10px",
  minWidth: "400px",
  margin: "10px",
};

const BulkSatoQueues: React.FC = () => {
  const [currentBSQueues, setCurrent] = useState<string[]>(null);
  const [potentialBSQueues, setPotential] = useState<string[]>(null);
  const [queueUpdates, setQueueUpdates] = useState<QueueUpdates>({
    queuesToAdd: [],
    queuesToRemove: [],
  });

  const loadQueueData = async () => {
    const res = await fetch("/api/sato/queues", {
      credentials: "include",
    });
    if (!res.ok)
      throw new Error(
        `An issue occurred while retrieving bulk sato queue lists: ${await res.text()}`,
      );
    const { currentlyBulkSatoEligible, potentiallyBulkSatoEligible } =
      await res.json();
    setCurrent(
      currentlyBulkSatoEligible.sort((a: string, b: string) =>
        a.localeCompare(b),
      ),
    );
    setPotential(
      potentiallyBulkSatoEligible.sort((a: string, b: string) =>
        a.localeCompare(b),
      ),
    );
  };

  const addToList = (queue: string) => {
    setCurrent(
      [...currentBSQueues, queue].sort((a: string, b: string) =>
        a.localeCompare(b),
      ),
    );
    setPotential(potentialBSQueues.filter((q) => q !== queue));

    const { queuesToAdd, queuesToRemove } = queueUpdates;
    const wasPreviouslyRemoved = queuesToRemove.includes(queue);
    setQueueUpdates({
      queuesToAdd: wasPreviouslyRemoved
        ? [...queuesToAdd]
        : [...queuesToAdd, queue],
      queuesToRemove: wasPreviouslyRemoved
        ? queuesToRemove.filter((q) => q !== queue)
        : [...queuesToRemove],
    });
  };

  const removeFromList = (queue: string) => {
    if (queue === "default") {
      return;
    }
    setCurrent(currentBSQueues.filter((q) => q !== queue));
    setPotential(
      [...potentialBSQueues, queue].sort((a: string, b: string) =>
        a.localeCompare(b),
      ),
    );

    const { queuesToAdd, queuesToRemove } = queueUpdates;
    const wasPreviouslyAdded = queuesToAdd.includes(queue);
    setQueueUpdates({
      queuesToAdd: wasPreviouslyAdded
        ? queuesToAdd.filter((q) => q !== queue)
        : [...queuesToAdd],
      queuesToRemove: wasPreviouslyAdded
        ? [...queuesToRemove]
        : [...queuesToRemove, queue],
    });
  };

  const saveChanges = async () => {
    const res = await fetch("/api/sato/queues", {
      method: "PUT",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(queueUpdates),
    });
    if (!res.ok) {
      throw new Error(
        `An issue occurred while updating bulk sato eligibility queue list: ${await res.text()}`,
      );
    }

    const { currentlyBulkSatoEligible, potentiallyBulkSatoEligible } =
      await res.json();
    setCurrent(
      currentlyBulkSatoEligible.sort((a: string, b: string) =>
        a.localeCompare(b),
      ),
    );
    setPotential(
      potentiallyBulkSatoEligible.sort((a: string, b: string) =>
        a.localeCompare(b),
      ),
    );
    setQueueUpdates({ queuesToAdd: [], queuesToRemove: [] });
  };

  useEffect(() => {
    loadQueueData();
  }, []);

  const SaveButton: React.FC = () => {
    const { queuesToAdd, queuesToRemove } = queueUpdates;
    const [savingState, setSavingState] = useState<string>("Save Changes");

    const disableToggle =
      !(queuesToAdd.length || queuesToRemove.length) ||
      savingState === "Saving...";

    return (
      <button
        onClick={async () => {
          setSavingState("Saving...");
          await saveChanges();
          window.alert("Updates have been saved!");
          setSavingState("Save Changes");
        }}
        disabled={disableToggle}
      >
        {savingState}
      </button>
    );
  };

  if (!(currentBSQueues && potentialBSQueues)) {
    return <div>Loading...</div>;
  }

  return (
    <div id="bsq-page-container">
      <Header url="/management">Set Bulk Sato Eligiblity</Header>
      <div style={{ display: "flex" }}>
        <QueuesTable
          queueList={currentBSQueues}
          queueOnClick={removeFromList}
          tableHeader={"Queues Currently Eligible"}
          tableStyle={tableStyle}
        />
        <QueuesTable
          queueList={potentialBSQueues}
          queueOnClick={addToList}
          tableHeader={"Queues Available To Add"}
          tableStyle={tableStyle}
        />
      </div>
      <div style={{ margin: "10px", textAlign: "right" }}>
        <SaveButton />
      </div>
    </div>
  );
};

export default BulkSatoQueues;
