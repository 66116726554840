import React from "react";
import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

interface genericFunction {
  (props?: any): any;
}
interface Client {
  id?: number;
  name: string;
  color: string;
  brands: string[];
}

interface ClientContextValue {
  clients?: Array<Client>;
  refreshClientList: () => Promise<void>;
}

const ClientContext = React.createContext<ClientContextValue>({
  clients: null,
  refreshClientList: null,
});

const ClientProvider: React.FC = ({ children }) => {
  const [clients, setClients] = useState(null);

  const refreshClientList = async () => {
    const res = await fetch("/api/client", {
      credentials: "include",
    });
    const clients = await res.json();
    setClients(clients);
  };

  useEffect(() => {
    refreshClientList();
  }, []);

  if (!clients) return <div>Loading...</div>;

  return (
    <ClientContext.Provider
      value={{
        clients,
        refreshClientList,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};

const useClientList = () => {
  const { clients } = useContext(ClientContext);
  return clients;
};

const useClient = (clientId) => {
  const clients = useClientList();
  const client = clients.find((client) => client.id == clientId);
  return client || null;
};

const useCurrentClient = () => {
  const { clientId } = useParams<{ clientId }>();
  const client = useClient(clientId);
  return client;
};

const withCurrentClient = (Component) => {
  const WithCurrentClient = (props) => {
    const client = useCurrentClient();
    return <Component {...props} client={client} />;
  };
  return WithCurrentClient;
};

export {
  ClientContext,
  ClientProvider,
  useClientList,
  useClient,
  useCurrentClient,
  withCurrentClient,
};
