import React from "react";
import { useState } from "react";
import { Header } from "../../../components/common/Header";
import Linput from "../../../components/common/Linput";

let style = {
  mainDiv: {
    textAlign: "center" as "center",
  },
  input: {
    float: "none",
  },
};

const ManageBatchWipeoff: React.FC = (props) => {
  const [wipeoffInput, updateWipeoffInput] = useState("");
  const [notice, setNotice] = useState(null);
  const wipeoff = async () => {
    setNotice("Wiping off...");
    await (() => new Promise((r) => setTimeout(r, 500)))();
    const response = await fetch(
      `/api/shipment/batch/wipeoff/${wipeoffInput}`,
      {
        credentials: "include",
        method: "POST",
      },
    );
    setNotice(await response.text());
  };

  return (
    <div style={style.mainDiv}>
      <Header url="./">Wipeoff Batch Dashboard</Header>
      <div>
        <Linput
          label="Enter Batch ID to wipe off"
          name="enter-wipeoff"
          value={wipeoffInput}
          onChange={(v) => updateWipeoffInput(v)}
          onEnter={() => wipeoff()}
        />
        <button onClick={() => wipeoff()}>Wipe Off Batch</button>
      </div>
      {!!notice && (
        <div>
          <h1>{notice}</h1>
        </div>
      )}
    </div>
  );
};

export default ManageBatchWipeoff;
