import React from "react";
import fetch from "isomorphic-fetch";
import { actions, store } from "../rdx/move";
import AdjustmentItem from "../components/AdjustmentItem";
import { Header } from "../../../components/common/Header";
import Linput from "../../../components/common/Linput";
import FakeTable from "../../../components/common/FakeTable";
import { SimpleSelect } from "../../../components/common/form-components";
import Modal from "react-modal";

const tableKeys = [
  "title",
  "sku",
  "available",
  "onHand",
  "id",
  "type",
  "palletType",
  "status",
  "skuOwner",
  "upc",
  "clientId",
  "club_id_sm",
];
const tableHeaders = [
  "Title",
  "Sku",
  "Available",
  "On Hand",
  "Location Id",
  "Location Type",
  "Pallet Type",
  "Status",
  "Sku Owner",
  "Upc",
  "Co Id",
  "Club Id",
];

export default class InventoryMove extends React.Component<{}, any> {
  constructor(props) {
    super(props);
    this.state = store.getState();
    store.subscribe(() => this.setState(store.getState()));
  }

  updateSearchType(which, val) {
    store.dispatch(
      actions.setSearchType(which, val.target.selectedOptions[0].value),
    );
  }

  updateSearchInput(which, val) {
    store.dispatch(actions.setSearchInput(which, val));
  }

  async searchInventory() {
    const { searchType, searchInput } = this.state.from;
    try {
      let route = "";
      if (searchType === "locationId") {
        route = `/${searchInput}`;
      } else {
        route = `?${searchType}=${searchInput}`;
      }
      const data = await fetch(`/api/location${route}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      let locations = await data.json();
      locations = Array.isArray(locations) ? locations : [locations];
      store.dispatch(actions.setItems("from", locations));
    } catch (error) {
      console.error("Error searching inventory:", error);
    }
  }

  updateModal(which, item) {
    store.dispatch(actions.setCurrentItem(which, item));
    store.dispatch(actions.toggleModal(true));
  }

  toggleModal(state) {
    store.dispatch(actions.clearAdjustmentPanel());
    store.dispatch(actions.setCurrentItem("to", {}));
    store.dispatch(actions.setCurrentItem("from", {}));
    store.dispatch(actions.setItems("to", []));
    store.dispatch(actions.toggleModal(false));
  }

  async searchLocations() {
    const { searchInput } = this.state.to;
    let queryString = "";

    if (this.state.to.searchType === "empty-back") {
      queryString =
        "?empty=true&unassigned=true&status=A&type=VNAP,STDP,STDP CABINET";
    } else if (this.state.to.searchType === "empty-flow") {
      queryString = "?unassigned=true&type=flow&status=A";
    } else if (this.state.to.searchType === "locationId") {
      queryString = `/${searchInput}`;
    } else if (this.state.to.searchType === "sku") {
      queryString = `?empty=true&status=A&sku=${searchInput}`;
    } else if (this.state.to.searchType === "backAssigned") {
      queryString =
        "?empty=true&assigned=true&status=A&type=STDO,STDP,VNAO,VNAP,BULK,STDP CABINET";
    } else if (this.state.to.searchType === "flowAssigned") {
      queryString = "?empty=true&type=flow&status=A&assigned=true";
    } else if (this.state.to.searchType === "currentSku") {
      queryString = `?sku=${this.state.from.currentItem.sku}`;
    } else if (this.state.to.searchType === "out") {
      queryString = ""; // FIXME:
    }

    try {
      const res = await fetch(`/api/location${queryString}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      let locations = await res.json();
      locations = Array.isArray(locations) ? locations : [locations];
      store.dispatch(actions.setCurrentPage(0));
      store.dispatch(actions.setItems("to", locations));
    } catch (error) {
      console.error("Error searching for locations", error);
    }
  }

  nextPage() {
    store.dispatch(actions.setCurrentPage(this.state.currentPage + 1));
  }

  prevPage() {
    store.dispatch(actions.setCurrentPage(this.state.currentPage - 1));
  }

  adjustQuantity(val) {
    let newValue = this.state.adjustmentPanel.adjustmentValue + val;
    if (newValue < 0) newValue = 0;
    else if (newValue > this.state.from.currentItem.available)
      newValue = this.state.from.currentItem.available;
    store.dispatch(actions.setAdjustmentValue(newValue));
  }

  updateSelectedQuantity(val) {
    let newValue = parseInt(val.target.value);
    if (newValue < 0 || isNaN(newValue)) newValue = 0;
    else if (newValue > this.state.from.currentItem.available)
      newValue = this.state.from.currentItem.available;
    store.dispatch(actions.setAdjustmentValue(newValue));
  }

  updateReasonCode(val) {
    store.dispatch(
      actions.updateReasonCode(val.target.selectedOptions[0].value),
    );
  }

  updateReasonComments(val) {
    store.dispatch(actions.updateReasonComments(val.target.value));
  }

  async createMove() {
    if (Object.keys(this.state.to.currentItem).length === 0) return;
    let av = parseInt(this.state.adjustmentPanel.adjustmentValue);
    if (isNaN(av)) return alert("Adjustment value isnt a number!");
    const {
      adjustmentPanel,
      from: { currentItem: from },
      to: { currentItem: to },
    } = this.state;
    const query = `
      The chosen destination (ID = ${to.id}) is currently disabled.
      Completing this move will make the inventory inaccessible.
      Are you sure you want to make a move to this location?
    `;
    const answer = to.status === "D" ? confirm(query) : true;
    if (!answer) return;
    try {
      await fetch(`/api/move`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          pickup: from.id,
          dropOff: to.id,
          moveQuantity: adjustmentPanel.adjustmentValue,
          sku: from.sku,
          isNormal: true,
          orderId: adjustmentPanel.orderId.trim().replace("\t", ""),
          reason: adjustmentPanel.reasonCode,
          sentToNs: false,
          isThirdPartyNightly: false,
          memo: adjustmentPanel.reasonComments,
          status: "PENDING",
          type: "NORMAL",
        }),
      });
      store.dispatch(actions.clearAdjustmentPanel());
      store.dispatch(actions.setCurrentItem("to", {}));
      store.dispatch(actions.setCurrentItem("from", {}));
      store.dispatch(actions.setItems("to", []));
      store.dispatch(actions.toggleModal(false));
      this.searchInventory();
      alert("Move Created");
    } catch (error) {
      console.error("Error creating move:", error);
    }
  }

  async clearLocation(item, evt) {
    evt.stopPropagation();
    console.log(item);
    const clearIt = confirm("Clear location of this sku?");
    if (!clearIt) return;
    try {
      await fetch(`/api/location/${item.id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          sku: "",
          title: "",
        }),
      });
      alert("Cleared");
      this.searchLocations();
    } catch (error) {
      console.error("Error clearing location:", error);
      alert("Something went wrong. Unable to clear location.");
    }
  }

  render() {
    return (
      <div>
        <Header url="./">Inventory Dashboard - Adjustments</Header>
        <SimpleSelect
          options={this.state.from.searchOptions}
          onChange={this.updateSearchType.bind(this, "from")}
        />
        <Linput
          label=""
          name="enter-sku"
          value={this.state.from.searchInput}
          onChange={this.updateSearchInput.bind(this, "from")}
          onEnter={this.searchInventory.bind(this)}
        />
        <button onClick={this.searchInventory.bind(this)}>Search</button>
        <FakeTable
          header={tableHeaders}
          data={this.state.from.items}
          keys={tableKeys}
          inserts={[]}
          rowClicked={(item) => this.updateModal("from", item)}
        />
        <Modal
          isOpen={this.state.showModal}
          onRequestClose={this.toggleModal.bind(this, false)}
        >
          <button
            onClick={this.toggleModal.bind(this, false)}
            className="modal-close-btn"
          >
            X
          </button>
          <FakeTable
            header={tableHeaders}
            data={[this.state.from.currentItem]}
            keys={tableKeys}
            inserts={[]}
            rowClicked={() => ""}
          />
          <div>
            <AdjustmentItem
              adjustmentValue={this.state.adjustmentPanel.adjustmentValue}
              adjustQuantity={this.adjustQuantity.bind(this)}
              updateSelectedQuantity={this.updateSelectedQuantity.bind(this)}
              reasonCodeChange={this.updateReasonCode.bind(this)}
              onReasonTextChange={this.updateReasonComments.bind(this)}
              reasonComments={this.state.adjustmentPanel.reasonComments}
              adjustInventory={this.createMove.bind(this)}
              showCommitButton={this.state.adjustmentPanel.reasonCode !== ""}
            />
            <input
              value={this.state.adjustmentPanel.orderId}
              onChange={(e) =>
                store.dispatch(actions.updateOrderId(e.target.value))
              }
              type="text"
              placeholder="ORDER ID[OPTIONAL]"
            />
          </div>
          <p style={{ textAlign: "center", fontSize: "2em" }}>TO LOCATION</p>
          <FakeTable
            header={tableHeaders}
            data={[this.state.to.currentItem]}
            keys={tableKeys}
            inserts={[]}
            rowClicked={() => {}}
          />
          <h1>Find Locations</h1>
          <SimpleSelect
            options={this.state.to.searchOptions}
            onChange={this.updateSearchType.bind(this, "to")}
          />
          <Linput
            label=""
            name="enter-location"
            value={this.state.to.searchInput}
            onChange={this.updateSearchInput.bind(this, "to")}
            onEnter={this.searchLocations.bind(this)}
          />
          <button onClick={this.searchLocations.bind(this)}>
            Search Locations
          </button>

          <FakeTable
            header={tableHeaders.concat("clear")}
            data={this.state.to.items
              .slice(
                this.state.currentPage * this.state.step,
                this.state.currentPage * this.state.step + this.state.step,
              )
              .map((x) => {
                if (x.onHand !== 0 || x.available !== 0) return x;
                x._DELETE_BTN = (
                  <button onClick={this.clearLocation.bind(this, x)}>
                    Clear Location
                  </button>
                );
                return x;
              })}
            keys={tableKeys.concat("_DELETE_BTN")}
            inserts={[]}
            rowClicked={(item) => this.updateModal("to", item)}
          />
          {this.state.currentPage > 0 ? (
            <button onClick={this.prevPage.bind(this)}>Prev</button>
          ) : (
            ""
          )}
          {this.state.currentPage * this.state.step <
          this.state.to.items.length ? (
            <button onClick={this.nextPage.bind(this)}>Next</button>
          ) : (
            ""
          )}
          <p>Total Available : {this.state.to.totalAvailable}</p>
          <p>Total On Hand : {this.state.to.totalOnHand}</p>
          <p>Total Locations : {this.state.to.totalLocations}</p>
        </Modal>
        <p>Total Available : {this.state.from.totalAvailable}</p>
        <p>Total On Hand : {this.state.from.totalOnHand}</p>
        <p>Total Locations : {this.state.from.totalLocations}</p>
      </div>
    );
  }
}
