import React, { useState } from "react";
import fetch from "isomorphic-fetch";
import XLSX from "xlsx";
import { Header } from "../../../components/common/Header";

const BulkManifest = (props) => {
  const [sheet, setSheet] = useState(null);
  const [sheetLoaded, setSheetLoaded] = useState(false);

  const loadSheet = (ev) => {
    setSheet(ev.target.files[0]);
    setSheetLoaded(true);
  };

  const uploadSheet = async () => {
    const fileReader = new FileReader();
    fileReader.onload = async () => {
      // xlsx can luckily read basic csv files, no need to detect and have branch handling cases
      const workbook = XLSX.read(fileReader.result, { type: "binary" });
      const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]];
      const [_, ...data] = XLSX.utils.sheet_to_json(firstWorksheet, {
        header: 1,
      });
      const ids = data.map((a) => parseInt(a[0])).filter((i) => !isNaN(i));
      try {
        console.log("ids:", ids); // TODO:
        await fetch(`/api/shipment/manifest`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ ids }),
        });
        alert("Force manifest successful");
      } catch (err) {
        alert("Error manifesting orders");
        console.error("Error force manifesting file:", error);
      } finally {
        setSheet(null);
        setSheetLoaded(false);
        const input = document.getElementById("file");
        input.value = "";
      }
    };
    fileReader.readAsBinaryString(sheet);
  };

  return (
    <div>
      <Header url="/management">Back to Warehouse Management</Header>
      <div style={{ margin: "10px" }}>
        <div style={{ maxWidth: "500px" }}>
          <label htmlFor="file">Upload file</label>
          <input
            id="file"
            name="file"
            type="file"
            accept=".csv,text/csv,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" // .file for chrome, full type for firefox
            onChange={loadSheet}
          />
          <button onClick={uploadSheet} disabled={!sheetLoaded}>
            Upload
          </button>
        </div>
      </div>
    </div>
  );
};

export default BulkManifest;
