import React from "react";
import { Header } from "../../components/common/Header";
import { Link, useRouteMatch } from "react-router-dom";

const Management: React.FC = () => {
  const { url } = useRouteMatch();
  let countUrl = null;
  if (/*window.MW_GLOBAL.level === '0'*/ true) {
    countUrl = (
      <Link to={url + "/counts/admin"} style={{ width: "50%" }}>
        Manage Counts
      </Link>
    );
  } else {
    countUrl = (
      <Link to={url + "/counts"} style={{ width: "50%" }}>
        Manage Count
      </Link>
    );
  }

  return (
    <div>
      <Header url="/">Warehouse Management</Header>

      <div data-role="content" className="content default">
        <div className="options" data-type="horizontal">
          <Link to={url + "/account"} style={{ width: "50%" }}>
            Account
          </Link>
          <Link to={url + "/sku"} style={{ width: "50%" }}>
            Manage SKUs
          </Link>
          <Link to={url + "/bulk-skus"} style={{ width: "50%" }}>
            Bulk Add Products
          </Link>
          <Link to={url + "/location"} style={{ width: "50%" }}>
            Manage Locations
          </Link>
          <Link to={url + "/wipeoff"} style={{ width: "50%" }}>
            Manage Order Wipeoffs
          </Link>
          <Link to={url + "/batch-wipeoff"} style={{ width: "50%" }}>
            Manage Batch Wipeoffs
          </Link>
          <Link to={url + "/print"} style={{ width: "50%" }}>
            Print
          </Link>
          <Link to={url + "/acs"} style={{ width: "50%" }}>
            ACS Returns
          </Link>
          {countUrl}
          <Link to={url + "/bulk-manifest"} style={{ width: "50%" }}>
            Bulk Manifest
          </Link>
          <Link to={url + "/invoice-templates"} style={{ width: "50%" }}>
            Invoice Templates
          </Link>
          <Link to={url + "/osm-file"} style={{ width: "50%" }}>
            Send OSM File
          </Link>
          <Link to={url + "/dhl-file"} style={{ width: "50%" }}>
            Send DHL File
          </Link>
          <Link to={url + "/unlock"} style={{ width: "50%" }}>
            Unlock Order
          </Link>
          <Link to={url + "/brands"} style={{ width: "50%" }}>
            Create Brand/Client
          </Link>
          <Link to={url + "/bulk-sato-titles"} style={{ width: "50%" }}>
            Bulk Sato Titles
          </Link>
          <Link to={url + "/bulk-sato-queues"} style={{ width: "50%" }}>
            Bulk Sato Queues
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Management;
