import React from "react";

type QueuesTableParams = {
  queueList: string[];
  queueOnClick: (queue: string) => void;
  tableHeader: string;
  tableStyle: React.CSSProperties;
  // tableHeaderStyle?: React.CSSProperties,
  // tableBodyStyle?: React.CSSProperties,
  // tableRowStyle?: React.CSSProperties,
};

export default class QueuesTable extends React.Component<QueuesTableParams> {
  render() {
    const { queueList, queueOnClick, tableHeader, tableStyle } = this.props;

    return (
      <table style={tableStyle}>
        <thead style={{ border: "5px solid black" }}>
          <tr
            style={{
              textAlign: "center",
              width: "100%",
              display: "table",
            }}
          >
            <td>{tableHeader}</td>
          </tr>
        </thead>
        <tbody
          style={{
            display: "block",
            height: "500px",
            overflowY: "scroll",
          }}
        >
          {queueList.map((q) => (
            <tr
              key={q}
              onClick={() => queueOnClick(q)}
              style={{
                textAlign: "center",
                width: "100%",
                display: "table",
              }}
            >
              <td>{q}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
