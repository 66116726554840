import React from "react";
import { useState } from "react";
import { Header } from "../../components/common/Header";
import Linput from "../../components/common/Linput";
import FakeTable from "../../components/common/FakeTable";
import { Redirect } from "react-router-dom";
import { useCurrentClient } from "../../contexts/ClientContext";
import { Link, useRouteMatch } from "react-router-dom";

const Weight: React.FC = (props) => {
  const [searchInput, setSearchInput] = useState("");
  const [shipment, setShipment] = useState(null);
  const [notice, setNotice] = useState(null);
  const client = useCurrentClient();
  const { url } = useRouteMatch();

  if (!client) {
    return <Redirect to="/client" />;
  }

  const lookupOrder = async () => {
    setShipment(null);
    setNotice("Searching...");
    const response = await fetch(
      `/api/weight?clientId=${client.id}&search=${searchInput}`,
      {
        credentials: "include",
      },
    );
    if (response.status !== 200) {
      setNotice(await response.text());
    } else {
      const shipment = await response.json();
      setShipment(shipment);
    }
  };

  const acceptWeight = async () => {
    const response = await fetch(`/api/weight/accept`, {
      credentials: "include",
      method: "POST",
      body: JSON.stringify({ id: shipment.id }),
      headers: { "Content-Type": "application/json" },
    });
    if (response.status !== 200) {
      setShipment(null);
      setNotice(await response.text());
    } else {
      await lookupOrder();
    }
  };

  const manifest = async () => {
    const response = await fetch(`/api/shipment/manifest`, {
      credentials: "include",
      method: "POST",
      body: JSON.stringify({ ids: [shipment.id] }),
      headers: { "Content-Type": "application/json" },
    });
    if (response.status !== 200) {
      setShipment(null);
      setNotice(await response.text());
    } else {
      setShipment(null);
      setNotice("Package Manifested, place in the chute.");
    }
  };

  return (
    <div>
      <Header url="../">{client.name + " Weight Check"}</Header>
      <Linput
        name="enter-invoice-number"
        value={searchInput}
        onChange={(v) => setSearchInput(v)}
        onEnter={() => lookupOrder()}
      />
      <button onClick={() => lookupOrder()}>Check Weight</button>
      {shipment ? (
        <div>
          Order: <span>{shipment.id}</span>
          <br />
          Barcode: <span>{shipment.barcode}</span>
          <br />
          Titles:
          <br />
          <FakeTable
            header={["Sku #", "Title", "Weight"]}
            data={shipment.sku_data}
            keys={["sku", "title", "sku_weight"]}
            inserts={[]}
            rowClicked={() => {}}
          />
          Expected Weight: <span>{shipment.expected_weight}</span>
          <br />
          Actual Weight: <span>{shipment.actual_weight}</span>
          <br />
          {Math.abs(shipment.expected_weight - shipment.actual_weight) >
          0.01 ? (
            <button onClick={() => acceptWeight()}>Accept Weight</button>
          ) : (
            <>
              <button onClick={() => manifest()}>
                Manifest and place in chute
              </button>
              <br />
              or place package back on the sorter machine to reprocess.
            </>
          )}
        </div>
      ) : (
        <div>{notice}</div>
      )}
    </div>
  );
};

export default Weight;
