import React from "react";
import { useContext } from "react";
import { Switch, Route, useRouteMatch, Redirect, Link } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import HomePage from "./pages/home";
import LoginPage from "./pages/login";
import ClientPage from "./pages/client";
import ReceivingPage from "./pages/receiving";
import ReceivingNewInventory from "./pages/receiving/pages/InputInventory";
import ReceivingMoves from "./pages/receiving/pages/ReceivingMoves";
import InventoryPage from "./pages/inventory";
import InventoryAdjustment from "./pages/inventory/adjustment";
import InventoryFreeze from "./pages/inventory/pages/InventoryFreeze";
import InventoryMoves from "./pages/inventory/pages/InventoryMove";
import InventoryPending from "./pages/inventory/pages/PendingMoves";
import ReturnsPage from "./pages/returns";
import ReturnsCredit from "./pages/returns/pages/Credit";
import ReturnsPutaway from "./pages/returns/pages/Putaway";
import ReturnsMoves from "./pages/returns/pages/Moves";
import Needs from "./pages/inventory/pages/needs";
import LookupOrder from "./pages/lookup/pages/LookupOrder";
import WipeoffOrder from "./pages/management/pages/wipeoffs";
import WipeoffBatch from "./pages/management/pages/batch_wipeoff";
import Management from "./pages/management";
import AccountPage from "./pages/management/pages/account";
import ManageSku from "./pages/management/ManageSku";
import ManageLocations from "./pages/management/pages/manage-locations";
import PrintClientSelect from "./pages/management/pages/print-client-select";
import PrintBusinessSelect from "./pages/management/pages/print-business-select";
import PrintQueueSelect from "./pages/management/pages/print-queue-select";
import PrintToil from "./pages/management/pages/print-toil";
import BulkManifest from "./pages/management/pages/BulkManifest";
import BulkSkus from "./pages/management/pages/BulkSkus";
import BulkSatoTitles from "./pages/management/pages/BulkSatoTitles";
import BulkSatoTitlesConfirmDelete from "./pages/management/pages/BulkSatoTitlesConfirmDelete";
import BulkSatoQueues from "./pages/management/pages/BulkSatoQueues";
import InvoiceTemplates from "./pages/management/pages/invoice_templates/InvoiceTemplates";
import InvoiceTemplate from "./pages/management/pages/invoice_templates/InvoiceTemplate";
import InvoiceRender from "./pages/management/pages/invoice_templates/InvoiceRender";
import SendOSM from "./pages/management/pages/sendosm";
import SendDHL from "./pages/management/pages/sendDHL";
import UnlockOrder from "./pages/management/pages/unlock";
import ManageBrand from "./pages/management/pages/brands";
import Weight from "./pages/weight";
import { ClientProvider, useCurrentClient } from "./contexts/ClientContext";
import { UserProvider, UserContext } from "./contexts/UserContext";
import ManageBrands from "./pages/management/pages/brands";

const ClientRoutes: React.FC = () => {
  let { path } = useRouteMatch();

  const client = useCurrentClient();
  if (!client) {
    return <Redirect to="/client" />;
  }
  return (
    <Switch>
      <Route exact path={path}>
        <HomePage />
      </Route>
      <Route exact path={`${path}/receiving`}>
        <ReceivingPage />
      </Route>
      <Route exact path={`${path}/receiving/input`}>
        <ReceivingNewInventory />
      </Route>
      <Route exact path={`${path}/receiving/moves`}>
        <ReceivingMoves />
      </Route>
      <Route exact path={`${path}/inventory`}>
        <InventoryPage />
      </Route>
      <Route exact path={`${path}/inventory/adjustment`}>
        <InventoryAdjustment />
      </Route>
      <Route exact path={`${path}/inventory/freeze`}>
        <InventoryFreeze />
      </Route>
      <Route exact path={`${path}/inventory/pending-moves`}>
        <InventoryPending />
      </Route>
      <Route exact path={`${path}/inventory/move`}>
        <InventoryMoves />
      </Route>
      <Route exact path={`${path}/returns`}>
        <ReturnsPage />
      </Route>
      <Route exact path={`${path}/returns/credit`}>
        <ReturnsCredit />
      </Route>
      <Route exact path={`${path}/returns/putaway`}>
        <ReturnsPutaway />
      </Route>
      <Route exact path={`${path}/returns/moves`}>
        <ReturnsMoves />
      </Route>
      <Route exact path={`${path}/moving`}>
        <Needs />
      </Route>
      <Route exact path={`${path}/lookup`}>
        <LookupOrder />
      </Route>
      <Route exact path={`${path}/weight`}>
        <Weight />
      </Route>
    </Switch>
  );
};

const ClientSelectRoutes: React.FC = (props) => {
  return (
    <Switch>
      <Route exact path="/client">
        <ClientPage />
      </Route>
      <Route path="/client/:clientId">
        <ClientRoutes />
      </Route>
    </Switch>
  );
};

const AuthenticatedRoutes: React.FC = () => {
  const { currentUser, firstLoginAttempted, logout } = useContext(UserContext);
  if (!firstLoginAttempted) return <div>Loading...</div>;
  if (!currentUser) {
    return <Redirect to="/login" />;
  }
  return (
    <ClientProvider>
      <Switch>
        <Route exact path="/">
          <div>
            <Link to="/client">Client-select</Link>
          </div>
        </Route>
        <Route exact path="/logout">
          {() => logout() && <Redirect to="/login" />}
        </Route>
        <Route exact path="/management">
          <Management />
        </Route>
        <Route path="/client">
          <ClientSelectRoutes />
        </Route>
        <Route exact path="/management/account">
          <AccountPage />
        </Route>
        <Route exact path="/management/sku">
          <ManageSku />
        </Route>
        <Route exact path="/management/bulk-sato-titles">
          <BulkSatoTitles />
        </Route>
        <Route exact path="/management/bulk-sato-titles-delete">
          <BulkSatoTitlesConfirmDelete />
        </Route>
        <Route exact path="/management/location">
          <ManageLocations />
        </Route>
        <Route exact path="/management/wipeoff">
          <WipeoffOrder />
        </Route>
        <Route exact path="/management/batch-wipeoff">
          <WipeoffBatch />
        </Route>
        <Route exact path="/management/bulk-manifest">
          <BulkManifest />
        </Route>
        <Route exact path="/management/bulk-skus">
          <BulkSkus />
        </Route>
        <Route exact path="/management/invoice-templates">
          <InvoiceTemplates />
        </Route>
        <Route exact path="/management/invoice-templates/render/:id">
          <InvoiceRender />
        </Route>
        <Route exact path="/management/invoice-templates/:id">
          <InvoiceTemplate />
        </Route>
        <Route exact path="/management/print">
          <PrintClientSelect />
        </Route>
        <Route exact path="/management/print/:clientId">
          <PrintBusinessSelect />
        </Route>
        <Route exact path="/management/print/:clientId/:businessId">
          <PrintQueueSelect />
        </Route>
        <Route exact path="/management/print-toil">
          <PrintToil />
        </Route>
        <Route exact path="/management/osm-file">
          <SendOSM />
        </Route>
        <Route exact path="/management/dhl-file">
          <SendDHL />
        </Route>
        <Route exact path="/management/unlock">
          <UnlockOrder />
        </Route>
        <Route exact path="/management/brands">
          <ManageBrands />
        </Route>
        <Route exact path="/management/bulk-sato-queues">
          <BulkSatoQueues />
        </Route>
      </Switch>
    </ClientProvider>
  );
};

const Routes: React.FC = () => (
  <Switch>
    <Route exact path="/login">
      <LoginPage />
    </Route>
    <Route path="/">
      <AuthenticatedRoutes />
    </Route>
  </Switch>
);

const App: React.FC = () => (
  <UserProvider>
    <Router>
      <Routes />
    </Router>
  </UserProvider>
);

export default App;
