import React from "react";
import { useState } from "react";
import { Header } from "../../../components/common/Header";
import Linput from "../../../components/common/Linput";

let style = {
  mainDiv: {
    textAlign: "center" as "center",
  },
  input: {
    float: "none",
  },
};

const ManageWipeoff: React.FC = (props) => {
  const [searchInput, updateSearchInput] = useState("");
  const [shipment, setShipment] = useState(null);
  const [notice, setNotice] = useState(null);
  const searchForShipment = async () => {
    const response = await fetch(`/api/shipment/${searchInput}`, {
      credentials: "include",
    });
    if (response.status === 404) {
      const response2 = await fetch(`/api/shipment?externalId=${searchInput}`, {
        credentials: "include",
      });
      if (response2.status === 200) {
        const shipment = await response2.json();
        setShipment(shipment);
        setNotice(null);
      } else {
        setNotice(await response2.text());
      }
    } else if (response.status !== 200) {
      setNotice(await response.text());
    } else {
      const shipment = await response.json();
      setShipment(shipment);
      setNotice(null);
    }
  };
  const wipeoffShipment = async () => {
    const response = await fetch(`/api/shipment/${shipment.id}`, {
      method: "DELETE",
      credentials: "include",
    });
    setShipment(null);
    if (response.status === 200) {
      setNotice(`Shipment ID ${shipment.id} wiped off`);
    } else {
      setNotice(await response.text());
    }
  };
  const manifestShipment = async () => {
    const response = await fetch(`/api/shipment/${shipment.id}?manifest`, {
      method: "PUT",
      body: JSON.stringify({}),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    setShipment(null);
    if (response.status === 200) {
      setNotice(`Shipment ID ${shipment.id} wiped off`);
    } else {
      setNotice(await response.text());
    }
  };
  return (
    <div style={style.mainDiv}>
      <Header url="./">Wipeoffs Dashboard</Header>
      <div>
        <Linput
          label="Example: 07641567 or 25210565 or SOBSL3328093"
          name="enter-wipeoff"
          value={searchInput}
          onChange={(v) => updateSearchInput(v)}
          onEnter={() => searchForShipment()}
        />
        <button onClick={() => searchForShipment()}>Search</button>
      </div>
      {shipment && (
        <div>
          <p>ID: {shipment.id}</p>
          <p>External ID: {shipment.externalId}</p>
          <p>Brand TLA: {shipment.brandTla}</p>
          <p>Customer Account Id: {shipment.customerAccountId}</p>
          <p>Shipping Addressee: {shipment.address.addressee}</p>
          {shipment.manifestedAt && (
            <p>Shipment manifested: {shipment.manifestedAt}</p>
          )}
          {!shipment.manifestedAt && (
            <button onClick={() => wipeoffShipment()}>Wipeoff</button>
          )}
          {!shipment.manifestedAt && (
            <button onClick={() => manifestShipment()}>Manifest</button>
          )}
        </div>
      )}
      {notice && (
        <div>
          <h1>{notice}</h1>
        </div>
      )}
    </div>
  );
};

export default ManageWipeoff;
