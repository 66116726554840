import React from "react";
import { useEffect, useState } from "react";
import { withCurrentUser, User } from "../../../contexts/UserContext";
import { Header } from "../../../components/common/Header";
import FakeTable from "../../../components/common/FakeTable";
import { MoveItem } from "../../../components/Moves/MoveItem";
import Spinner from "../../../components/common/Spinner";

const movesHeaders = ["Deliver To", "Sku", "Move Qty"];
const movesKeys = ["dropOff", "sku", "moveQuantity"];
interface ReceivingMovesProps {
  currentUser: User;
}

const ReceivingMoves = ({ currentUser }: ReceivingMovesProps) => {
  const [movesList, setMovesList] = useState([]);
  const [inserts, setInserts] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    getMoves();
  }, []);

  const updateInsert = (moveId, updatedMoves = null) => {
    const list = updatedMoves || movesList;
    setInserts([]);
    const move = list.filter((m) => m.id === moveId)[0];
    if (!move) return;
    const index = list.indexOf(move);
    let buttonInnards = `Lock as ${currentUser.username}`;
    let btnOnClick = async () => await lockMove(move.id);
    let completeEl;
    let className = index % 2 === 0 ? "ft-even" : "ft-odd";
    if (move.lockUserId === currentUser.id) {
      className = "users-move";
      buttonInnards = "Unlock";
      btnOnClick = async () => await unlockMove(move.id);
      completeEl = (
        <button
          style={{ marginLeft: "10px" }}
          onClick={() => completeMove(move.id)}
        >
          Complete
        </button>
      );
    } else if (move.lockUserId) {
      buttonInnards = `Taken by user ${move.lockUserId}`;
      btnOnClick = null;
    }
    var insert = {
      insertId: move.id,
      className: className,
      el: (
        <MoveItem
          title={move.title}
          sku={move.sku}
          pickup={move.pickup}
          dropOff={move.dropOff}
          quantity={move.moveQuantity}
        >
          <button onClick={() => btnOnClick()}>{buttonInnards}</button>
          {completeEl}
        </MoveItem>
      ),
    };
    setInserts([insert]);
  };

  const completeMove = async (moveId) => {
    const isSure = confirm("Are you sure?");
    if (!isSure) return;
    try {
      setShowSpinner(true);
      await fetch(`/api/move/complete/${moveId}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });
      await getMoves();
    } catch (err) {
      console.error("Error completing move:", err);
    } finally {
      setShowSpinner(false);
    }
  };

  const unlockMove = async (moveId) => {
    try {
      setShowSpinner(true);
      await fetch(`/api/move/unlock/${moveId}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          lockUserId: currentUser.id,
        }),
      });
      setInserts([]);
      await getMoves();
    } catch (err) {
      console.error("Error unlocking move:", err);
    } finally {
      setShowSpinner(false);
    }
  };

  const lockMove = async (moveId) => {
    try {
      setShowSpinner(true);
      await fetch(`/api/move/lock/${moveId}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          lockUserId: currentUser.id,
        }),
      });
      await getMoves(moveId);
    } catch (err) {
      alert("Error locking move");
      console.error("Error locking move:", err);
    } finally {
      setShowSpinner(false);
    }
  };

  const getMoves = async (insertId = null) => {
    try {
      const res = await fetch(`/api/move?status=PENDING&movetypes=RECEIVE`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      const moves = await res.json();
      let iid;
      const updatedMoves = moves
        .map((m) => {
          let ret = Object.assign({}, m);
          if (insertId && m.id === insertId) iid = m.id;
          if (m.lockUserId) ret.className = "taken-move";
          if (m.lockUserId === currentUser.id) ret.className = "users-move";
          return ret;
        })
        .sort((a, b) => {
          if (a.sku < b.sku) return -1;
          if (a.sku > b.sku) return 1;
          if (parseInt(a.drop_off, 10) > parseInt(b.drop_off, 10)) return -1;
          if (parseInt(a.drop_off, 10) < parseInt(b.drop_off, 10)) return 1;
          if (a.id < b.id) return -1;
          if (a.id > b.id) return 1;
        });
      setMovesList(updatedMoves);
      if (iid) updateInsert(iid, updatedMoves);
    } catch (err) {
      console.error("Error getting moves", err);
      alert(`Error getting moves: ${err}`);
    }
  };
  return (
    <div>
      <Spinner show={showSpinner} />
      <Header url="./">Inventory Dashboard - Move</Header>
      <FakeTable
        header={movesHeaders}
        data={movesList}
        keys={movesKeys}
        inserts={inserts}
        rowClicked={(move) => {
          updateInsert(move.id);
        }}
      />
    </div>
  );
};

export default withCurrentUser(ReceivingMoves);
