import React from "react";

var styles = {
  container: {
    color: "red",
    fontSize: "small",
  },
};

interface NoteProps {
  color?: string;
}

export default class Note extends React.Component<NoteProps> {
  render() {
    var color = this.props.color;

    if (color) {
      styles.container.color = color;
    }

    return (
      <p style={styles.container}>
        <span>{this.props.children}</span>
      </p>
    );
  }
}
