import React from "react";
import { useState, useEffect } from "react";
import fetch from "isomorphic-fetch";
import { Header } from "../../../components/common/Header";
import FakeTable from "../../../components/common/FakeTable";
import Button from "../../../components/common/Button";
import { TitleData } from "src/api/sato/Sato";
import Linput from "../../../components/common/Linput";
import { Link } from "react-router-dom";

const BulkSatoTitles = () => {
  const useAsyncEffect = (asyncFn: any, deps: any) => {
    useEffect(() => {
      let cancelled = false;
      (async () => {
        const syncFn = await asyncFn();
        if (!cancelled && syncFn) {
          syncFn();
        }
      })();
      return () => {
        cancelled = true;
      };
    }, deps);
  };

  const [data, setData] = useState<TitleData[]>([]);
  const [newItem, setNewItem] = useState<TitleData>({
    sku: "",
    title: "",
    initials: "",
  });

  useAsyncEffect(async () => {
    let payload = await fetch("/api/sato/titles", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    let parsed_payload = await payload.json();
    return () => {
      setData(parsed_payload);
    };
  }, []);

  const deleteLine = async (item: TitleData) => {
    let sku = item.sku;
    let payload = await fetch(`/api/sato/titles/${sku}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    });
    let parsed_payload = await payload.json();
    setData(parsed_payload);
  };

  const create = async () => {
    try {
      if (newItem.sku === "" || newItem.initials === "") {
        throw new Error(`Enter values for sku and initials`);
      }
      let sku = newItem.sku;
      let payload = await fetch(`/api/sku/${sku}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (payload.status !== 200) {
        throw new Error(`Sku ${newItem.sku} not found`);
      }
    } catch (e) {
      alert(e);
      console.error(e);
      throw new Error(e);
    }
    let payload = await fetch("/api/sato/titles", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newItem),
    });
    let parsed_payload = await payload.json();
    setData(parsed_payload);
    setNewItem({
      sku: "",
      title: "",
      initials: "",
    });
  };

  return (
    <div>
      <Header url="/management">Bulk Sato Titles</Header>
      <FakeTable
        header={["Sku", "Initials", "Title"]}
        data={data}
        keys={["sku", "initials", "title"]}
        inserts={[]}
        rowClicked={(item) => {
          return;
        }}
        button={{
          buttonText: "X",
          buttonClicked: deleteLine.bind(this),
        }}
      />
      <Link to={"/management/bulk-sato-titles-delete"} style={{ width: "50%" }}>
        Delete All
      </Link>
      <Linput
        label="Enter new sku"
        name="enter-sku"
        value={newItem.sku}
        onChange={(v) =>
          setNewItem({
            sku: v,
            title: newItem.title,
            initials: newItem.initials,
          })
        }
      />
      <Linput
        label="Enter new initials"
        name="enter-initials"
        value={newItem.initials.toUpperCase()}
        onChange={(v) =>
          setNewItem({
            sku: newItem.sku,
            title: newItem.title,
            initials: v.toUpperCase(),
          })
        }
      />
      <Button type="submit" onClick={create.bind(this)}>
        +
      </Button>
    </div>
  );
};

export default BulkSatoTitles;
