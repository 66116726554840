import React from "react";
import { useEffect, useState } from "react";
import { Header } from "../../../components/common/Header";
import Linput from "../../../components/common/Linput";
import { getProductImage } from "../../../utils/get-product-image";
import fetch from "isomorphic-fetch";

const defaultItem = {
  name: "",
  sku: "",
  upc: "",
};

const PutawayReturns = () => {
  const [searchInput, setSearchInput] = useState("");
  const [item, setItem] = useState(defaultItem);
  const [quantity, setQuantity] = useState("");
  const [cart, setCart] = useState("");

  const reset = () => {
    setSearchInput("");
    setItem(defaultItem);
    setQuantity("");
    setCart("");
  };

  const getItem = async () => {
    try {
      const data = await fetch(`/api/sku/${searchInput}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      let skuData = await data.json();
      if (Array.isArray(skuData)) {
        skuData = skuData[0];
      }
      if (!skuData) return alert("Sku not found!");
      setItem(skuData);
    } catch (err) {
      console.error("ERROR getting item:", err);
      alert(`Error getting sku: ${JSON.stringify(err.responseText)}`);
    }
  };

  const restock = async () => {
    try {
      await fetch(`/api/move/returns/putaway`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ pickup: cart, item, quantity }),
      });
      reset();
      alert("Restock Succesful");
    } catch (err) {
      console.error("ERROR restocking:", err);
      const errorDetail = JSON.parse(err.responseText).error;
      if (errorDetail.indexOf("Unable to find valid location") > -1) {
        return alert("Unable to find valid location.");
      } else {
        alert("Something went wrong, unable to process putaway.");
      }
    }
  };

  return (
    <div id="putaway">
      <Header url="./">Returns - Credit</Header>
      <Linput
        name="search"
        label="Scan SKU/UPC"
        onChange={(val) => setSearchInput(val)}
        value={searchInput}
        onEnter={() => getItem()}
      />
      <button onClick={() => getItem()} style={{ display: "block" }}>
        Search
      </button>
      <div style={{ float: "left", width: "40%" }}>
        <img
          src={getProductImage(item.sku)}
          style={{ float: "left", paddingRight: "30px" }}
        />
        <ul className="putaway-item-details">
          <li>
            <h2>SKU </h2>
            <h3>{item.sku}</h3>
          </li>
          <li>
            <h2>UPC </h2>
            <h3>{item.upc}</h3>
          </li>
          <li>
            <h2>TITLE </h2>
            <h3>{item.name}</h3>
          </li>
        </ul>
      </div>

      <div style={{ float: "left" }}>
        <Linput
          name="search"
          label="Quantity"
          onChange={(val) => setQuantity(val)}
          value={quantity}
        />
        <Linput
          name="search"
          label="Cart"
          onChange={(val) => setCart(val)}
          value={cart}
        />
        <button onClick={() => restock()}>Restock</button>
      </div>
    </div>
  );
};

export default PutawayReturns;
