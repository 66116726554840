import React from "react";
import { Header } from "../../components/common/Header";
import { Redirect } from "react-router-dom";
import { useCurrentClient } from "../../contexts/ClientContext";
import { Link, useRouteMatch } from "react-router-dom";

const Receiving: React.FC = (props) => {
  const client = useCurrentClient();
  const { url } = useRouteMatch();
  if (!client) {
    return <Redirect to="/client" />;
  }
  return (
    <div>
      <Header url="../">{client.name + " Receivers Dashboard"}</Header>

      <div data-role="content" className="content default">
        <div className="options" data-type="horizontal">
          <Link to={url + "/input"} style={{ width: "50%" }}>
            Input New Inventory
          </Link>
          <Link to={url + "/moves"} style={{ width: "50%" }}>
            View Pending Moves
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Receiving;
