import { combineReducers, createStore } from "redux";
import {
  actions as asyncActions,
  actionTypes as asyncTypes,
} from "../../../rdx/async";
export let actionTypes = Object.assign({}, asyncTypes, {
  UPDATE_SEARCH_INPUT: "UPDATE_SEARCH_INPUT",
  UPDATE_SEARCH_TYPE: "UPDATE_SEARCH_TYPE",
  SET_ITEM: "SET_ITEM",
  SET_ITEM_CONTAINER_DISPLAY: "SET_ITEM_CONTAINER_DISPLAY",
  CLEAR_ITEM: "CLEAR_ITEM",
});

export let actions = Object.assign({}, asyncActions, {
  updateSearchInput: (input) => {
    return { type: actionTypes.UPDATE_SEARCH_INPUT, input };
  },

  updateSearchType: (input) => {
    return { type: actionTypes.UPDATE_SEARCH_TYPE, input };
  },

  setItem: (item) => {
    return { type: actionTypes.SET_ITEM, item };
  },

  setItemContainerDisplay: (display) => {
    return { type: actionTypes.SET_ITEM_CONTAINER_DISPLAY, display };
  },

  clearItem: () => {
    return { type: actionTypes.CLEAR_ITEM };
  },
});

const itemDefault = {
  locationId: "",
  status: "",
  locationType: "",
  skuOwner: "",
  coId: "",
  clubId: "",
  sku: "",
  title: "",
  available: "",
  onHand: "",
};

export const manageLocationApp = combineReducers({
  searchInput: (state = "", action) => {
    if (action.type === actionTypes.UPDATE_SEARCH_INPUT) return action.input;
    return state;
  },

  searchType: (state = "locationId", action) => {
    if (action.type === actionTypes.UPDATE_SEARCH_TYPE) return action.input;
    return state;
  },

  item: (state = itemDefault, action) => {
    console.log(action.type);
    if (action.type === actionTypes.SET_ITEM)
      return Object.assign({}, state, action.item);
    if (action.type === actionTypes.CLEAR_ITEM)
      return Object.assign({}, itemDefault);
    return state;
  },

  displayItemContainer: (state = false, action) => {
    if (action.type === "SET_ITEM_CONTAINER_DISPLAY") return action.display;
    return state;
  },
});

export const store = createStore(manageLocationApp);
