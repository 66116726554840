import React from "react";
import { actions, store } from "./rdx/adjustment";
import { Header } from "../../components/common/Header";
import Linput from "../../components/common/Linput";
import FakeTable from "../../components/common/FakeTable";
import { SimpleSelect } from "../../components/common/form-components";
import Modal from "react-modal";
import AdjustmentItem from "./components/AdjustmentItem";
import { withCurrentClient } from "../../contexts/ClientContext";

let tableKeys = [
  "title",
  "sku",
  "available",
  "onHand",
  "id",
  "type",
  "palletType",
  "status",
  "sku_owner",
  "upc",
  "clientId",
  "club_id_sm",
];
let tableHeaders = [
  "Title",
  "Sku",
  "Available",
  "On Hand",
  "Location Id",
  "Location Type",
  "Pallet Type",
  "Status",
  "Sku Owner",
  "Upc",
  "Co Id",
  "Club Id",
];

let btnStyle = {
  marginRight: "10px",
};

class Adjustment extends React.Component<{ client: any }, any> {
  constructor(props) {
    super(props);
    this.state = store.getState();
    console.log(this.state);
    store.subscribe(() => this.setState(store.getState()));
  }

  updateSearchInput(text) {
    store.dispatch(actions.updateSearch(text));
  }

  async searchInventory() {
    if (
      this.state.searchInput.trim().length < 3 &&
      this.state.searchType !== "locationId"
    ) {
      return store.dispatch(
        actions.setSearchError(
          "Please input a query with a length greater than 2",
        ),
      );
    }
    store.dispatch(actions.setSearchError(""));
    try {
      const { searchInput, searchType } = this.state;
      let route = "";
      if (searchType === "locationId") {
        route = `/${searchInput}`;
      } else {
        route = `?${searchType}=${searchInput}`;
      }
      const data = await fetch(`/api/location${route}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      let locations = await data.json();
      locations = Array.isArray(locations) ? locations : [locations];
      store.dispatch(actions.setItems(locations));
    } catch (error) {
      console.error("Error searching for locations:", error);
      // store.dispatch(actions.setError(error.message));
    }
  }

  updateSearchType(e, val) {
    store.dispatch(actions.setSearchType(val));
  }

  async adjustInventory() {
    const adjustmentValue = parseInt(
      this.state.adjustmentPanel.adjustmentValue,
    );
    if (isNaN(adjustmentValue)) return alert("Adjustment value isnt a number!");
    try {
      const {
        id: locationId,
        skuOwner: owner,
        sku,
        quantity: originalQuantity,
        palletType: currentPalletType,
      } = this.state.currentItem;
      const {
        reasonCode,
        reasonComments,
        palletType: adjustedPalletType,
      } = this.state.adjustmentPanel;
      const res = await fetch(
        `/api/location/adjustment/${this.state.currentItem.id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            locationId,
            sku,
            owner,
            originalQuantity,
            adjustmentValue,
            reasonCode,
            reasonComments: reasonComments || "",
            palletType:
              adjustedPalletType == null
                ? currentPalletType
                : adjustedPalletType,
          }),
        },
      );
      if (!res.ok) throw new Error("Could not save adjustment");
      this.searchInventory();
      alert("Saved");
      store.dispatch(actions.setCurrentItem({}));
      store.dispatch(actions.toggleModal(false));
      store.dispatch(actions.clearAdjustmentPanel());
    } catch (error) {
      console.error("Error adjusting inventory:", error);
      alert(`${error}`);
    }
  }

  updateReasonText(val) {
    store.dispatch(actions.updateReasonComments(val.target.value));
  }

  updateReasonCode(val) {
    store.dispatch(
      actions.updateReasonCode(val.target.selectedOptions[0].value),
    );
  }

  updateSelectedQuantity(val) {
    console.log(val.target.value, "UPDATE SELECT QUANTITY");
    store.dispatch(actions.setAdjustmentValue(val.target.value));
  }

  adjustQuantity(val) {
    store.dispatch(
      actions.setAdjustmentValue(
        (parseInt(this.state.adjustmentPanel.adjustmentValue) || 0) + val,
      ),
    );
  }

  toggleModal(visible) {
    store.dispatch(actions.toggleModal(visible));
  }

  updateModal(item) {
    store.dispatch(actions.setCurrentItem(item));
    store.dispatch(actions.toggleModal(true));
    store.dispatch(actions.setPalletType(item.palletType));
  }

  handlePalletType(e, val) {
    store.dispatch(actions.setPalletType(val));
  }

  render() {
    return (
      <div>
        <Header url="./">Inventory Dashboard - Adjustments</Header>
        <SimpleSelect
          options={this.state.searchOptions}
          onChange={this.updateSearchType.bind(this)}
        />
        <Linput
          label=""
          name="enter-sku"
          value={this.state.searchInput}
          onChange={this.updateSearchInput.bind(this)}
          onEnter={this.searchInventory.bind(this)}
        />
        <p className="error">{this.state.searchError}</p>
        <button onClick={this.searchInventory.bind(this)}>Search</button>
        <FakeTable
          header={tableHeaders}
          data={this.state.items}
          keys={tableKeys}
          inserts={[]}
          rowClicked={(item) => this.updateModal(item)}
        />

        <Modal
          isOpen={this.state.showModal}
          onRequestClose={this.toggleModal.bind(this, false)}
        >
          <button
            onClick={this.toggleModal.bind(this, false)}
            className="modal-close-btn"
          >
            X
          </button>
          <FakeTable
            header={tableHeaders}
            data={[this.state.currentItem]}
            keys={tableKeys}
            inserts={[]}
            rowClicked={() => {}}
          />
          <AdjustmentItem
            showPalletOption={true}
            handlePalletType={this.handlePalletType.bind(this)}
            initialPalletType={this.state.currentItem.palletType}
            adjustmentValue={this.state.adjustmentPanel.adjustmentValue}
            adjustQuantity={this.adjustQuantity.bind(this)}
            updateSelectedQuantity={this.updateSelectedQuantity.bind(this)}
            reasonCodeChange={this.updateReasonCode.bind(this)}
            onReasonTextChange={this.updateReasonText.bind(this)}
            reasonComments={this.state.adjustmentPanel.reasonComments}
            adjustInventory={this.adjustInventory.bind(this)}
            showCommitButton={this.state.adjustmentPanel.reasonCode !== ""}
          />
        </Modal>
        <p>Total Available : {this.state.totalAvailable}</p>
        <p>Total On Hand : {this.state.totalOnHand}</p>
        <p>Total Locations : {this.state.totalLocations}</p>
      </div>
    );
  }
}
export default withCurrentClient(Adjustment);
