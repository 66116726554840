import React from "react";
import ReactDOM from "react-dom";
import "./css/picnic.min.css";
import "./css/main.css";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import App from "./App";

window.onload = () => {
  const appDiv = document.createElement("div");
  const meta = document.createElement("meta");
  meta.name = "viewport";
  meta.content = "width=device-width, initial-scale=1 user-scalable=no";
  document.head.appendChild(meta);
  appDiv.className = "app-container";
  document.body.appendChild(appDiv);
  document.title = "NewWorld";

  ReactDOM.render(<App />, appDiv);
};
