import React from "react";

const Button = (props: any) => {
  if (props.size) {
    if (props.size === "sm") {
      props.style.fontSize = "1.1em";
    } else if (props.size === "md") {
      props.style.fontSize = "1.5em";
    } else if (props.size === "lg") {
      props.style.fontSize = "2.5em";
    }
  }

  return (
    <button style={props.style} className={props.type} onClick={props.onClick}>
      {props.children}
    </button>
  );
};

export default Button;
