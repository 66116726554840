import React from "react";
import fetch from "isomorphic-fetch";
import { Header } from "../../../components/common/Header";
import Linput from "../../../components/common/Linput";
import { SimpleSelect } from "../../../components/common/form-components";
import FakeTable from "../../../components/common/FakeTable";

let tableKeys = [
  "title",
  "sku",
  "available",
  "onHand",
  "id",
  "type",
  "palletType",
  "status",
  "skuOwner",
];
let tableHeaders = [
  "Title",
  "Sku",
  "Available",
  "On Hand",
  "Location Id",
  "Location Type",
  "Pallet Type",
  "Status",
  "Sku Owner",
];
const searchOptions = [
  {
    value: "sku",
    displayText: "SKU",
  },
  {
    value: "title",
    displayText: "Title",
  },
  {
    value: "locationId",
    displayText: "Location ID",
  },
  {
    value: "upc",
    displayText: "UPC/ISBN",
  },
];

interface FreezeState {
  searchText: string;
  searchType: string;
  searchOptions: any[];
  inventoryItemsList: any[];
  showSpinner: boolean;
  error: string;
}

export default class Freeze extends React.Component<{}, FreezeState> {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      searchType: "sku",
      searchOptions,
      inventoryItemsList: [],
      showSpinner: false,
      error: "",
    };
  }

  async search() {
    if (this.state.searchText.trim().length < 3) {
      this.setState({
        error: "Please input a query with a length greater than 3",
      });
      return;
    }
    this.setState({ error: "", showSpinner: true });

    const { searchText, searchType } = this.state;
    try {
      let route = "";
      if (searchType === "locationId") {
        route = `/${searchText}`;
      } else {
        route = `?${searchType}=${searchText}`;
      }
      const data = await fetch(`/api/location${route}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      const locations = await data.json();
      this.setState({
        showSpinner: false,
        inventoryItemsList: Array.isArray(locations) ? locations : [locations],
      });
      return locations;
    } catch (error) {
      console.error("Error searching inventory:", error);
      this.setState({ error: error.message });
    } finally {
      this.setState({ showSpinner: false });
    }
  }

  async toggleItemStatus({ id, status }) {
    let newStatus = "A";
    if (status === "A") newStatus = "F";
    try {
      await fetch(`/api/location/${id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          status: newStatus,
        }),
      });
      this.search();
    } catch (error) {
      console.error("Error freezing item:", error);
      alert("Error freezing item");
    }
  }

  render() {
    return (
      <div>
        <Header url="./">Inventory Dashboard - Adjustments</Header>
        <SimpleSelect
          options={this.state.searchOptions}
          onChange={(ev, val) => this.setState({ searchType: val })}
        />
        <Linput
          label=""
          name="enter-sku"
          value={this.state.searchText}
          onChange={(ev) => this.setState({ searchText: ev })}
          onEnter={this.search.bind(this)}
        />

        <button type="submit" onClick={this.search.bind(this)}>
          Search
        </button>
        <FakeTable
          header={tableHeaders}
          data={this.state.inventoryItemsList.sort((a, b) => a.id - b.id)}
          keys={tableKeys}
          inserts={[]}
          rowClicked={this.toggleItemStatus.bind(this)}
        />
      </div>
    );
  }
}
