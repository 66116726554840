import React from "react";
import { useState } from "react";
import fetch from "isomorphic-fetch";
import { Header } from "../../components/common/Header";
import Linput from "../../components/common/Linput";
import ItemDetails from "./components/ItemDetails";

const itemDefault = {
  sku: "",
  upc: "",
  title: "",
  length: "",
  width: "",
  depth: "",
  weight: "",
  attachWeight: "",
  coId: "",
  clubId: "",
  releaseDate: "",
  formatCode: "",
  palletQty: "",
  obsoleteFlag: "",
  clientId: "",
};

const ManageSku = () => {
  const [item, setItem] = useState(itemDefault);
  const [searchInput, setSearchInput] = useState("");
  const [displayItemContainer, setDisplayItemContainer] = useState(false);
  const [searchBy, setSearchBy] = useState("sku");
  const [createNew, setCreateNew] = useState(false);

  const updateItem = (key) => {
    return (e) => {
      const { value } = e.target;
      setItem({ ...item, ...{ [key]: value } });
    };
  };

  const getItem = async () => {
    try {
      if (!searchInput) throw new Error("Invalid search input");
      let url = `/api/sku/${searchInput}`;
      if (searchBy === "UPC") {
        url = `/api/sku?upc=${searchInput}`;
      }
      const data = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      let skuData = await data.json();
      if (Array.isArray(skuData)) {
        skuData = skuData[0];
      }
      if (!skuData) return alert("Sku not found!");
      const {
        sku,
        name: title,
        upc,
        width,
        height: length,
        depth,
        weight,
        attachWeight: attachWeight,
        clientId,
        coId,
        clubId,
        formatCode,
        palletQty,
        createdAt,
        eos,
      } = skuData;
      const releaseDate = createdAt ? createdAt.split(" ")[0] : "";
      const obsoleteFlag = eos ? "Y" : "N";
      setDisplayItemContainer(true);
      setItem({
        sku,
        upc,
        title,
        length,
        width,
        depth,
        weight,
        attachWeight,
        clientId,
        coId,
        clubId,
        releaseDate,
        formatCode,
        palletQty,
        obsoleteFlag,
      });
    } catch (error) {
      console.error("Error getting sku:", error);
      alert(`Error getting sku: ${error}`);
    }
  };

  const saveItem = async () => {
    try {
      let url = `/api/sku/${item.sku}`;
      let method = "PUT";
      if (createNew) {
        method = "POST";
        url = "/api/sku";
      }
      await fetch(url, {
        method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...item,
          name: item.title,
          description: item.title,
          height: item.length,
        }),
      });
      alert("saved!");
      await getItem();
    } catch (error) {
      console.error(error);
      await getItem();
    }
  };
  const newItem = () => {
    setDisplayItemContainer(true);
    setCreateNew(true);
    setItem({ ...itemDefault, ...{ sku: searchInput } });
  };

  const ItemContainer = (
    <div>
      <ItemDetails
        item={item}
        updateItem={(key) => updateItem(key)}
        isSkuManager={true}
      />
    </div>
  );
  const inputLabelText =
    searchBy === "sku" ? "Enter Sku ex 1402560" : "Enter UPC ex 9780553392364";
  return (
    <div>
      <Header url="/management">Sku Management</Header>

      <div id="leftReceivers" style={{ padding: 10 }}>
        <label>
          Choose to search by Sku or UPC:
          <select
            id="searchType"
            value={searchBy}
            onChange={(event) => setSearchBy(event.target.value)}
          >
            <option value="sku">Search by Sku</option>
            <option value="UPC">Search by UPC</option>
          </select>
        </label>
        <Linput
          label={inputLabelText}
          name="enter-sku"
          value={searchInput}
          onChange={(val) => setSearchInput(val)}
          onEnter={async () => await getItem()}
        />
        <button onClick={async () => await getItem()}>Search</button>
        <button
          onClick={() => newItem()}
          style={{ marginLeft: "50px", background: "red" }}
        >
          New
        </button>
      </div>
      {displayItemContainer ? ItemContainer : null}
      {displayItemContainer ? (
        <div style={{ fontSize: "1.3em" }}>
          <button onClick={() => saveItem()}>Save</button>
          <button
            style={{ margin: "20px", background: "red" }}
            onClick={() => setItem(itemDefault)}
          >
            Clear
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default ManageSku;
