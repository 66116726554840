import React from "react";
import { useState } from "react";
import { Header } from "../../../components/common/Header";
import Linput from "../../../components/common/Linput";

let style = {
  mainDiv: {
    textAlign: "center" as "center",
  },
  input: {
    float: "none",
  },
};

const ManageBrands: React.FC = (props) => {
  const [clientInput, updateClientInput] = useState("");
  const [brandInput, updateBrandInput] = useState("");
  const [notice, setNotice] = useState(null);
  const createClient = async () => {
    setNotice("Creating Client...");
    await (() => new Promise((r) => setTimeout(r, 500)))();
    const response = await fetch(`/api/client/`, {
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify(JSON.parse(clientInput)),
    });
    setNotice("Created, New Client ID:" + (await response.text()));
  };
  const createBrand = async () => {
    setNotice("Creating Brand...");
    await (() => new Promise((r) => setTimeout(r, 500)))();
    const response = await fetch(`/api/brand/`, {
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify(JSON.parse(brandInput)),
    });
    setNotice(await response.text());
  };

  return (
    <div style={style.mainDiv}>
      <Header url="./">Brand/Client Creation MVP</Header>
      <div>
        Create the client. Then create the brand. Example JSONs: Fill in
        carefully! Pager Duty task to delete brands/clients.
        <br></br>
        <hr></hr>
        &#123; "name": "a new client", "color": "#123456" &#125;
        <hr></hr>
        &#123; "clientId": 321, "name": "my brand", "tla": "ABC",
        "customerServiceEmail": "something.here@site.com",
        "customerServiceNumber": "1-123-456-7890", "postalClasses":
        ["BPM","PARCEL_SELECT_LIGHTWEIGHT","MEDIA_MAIL","PARCEL_SELECT","PRIORITY_MAIL"],
        "mailerId": "12345678" &#125;
      </div>
      <hr></hr>
      <div>
        <Linput
          label="Enter client json"
          name="enter-client"
          value={clientInput}
          onChange={(v) => updateClientInput(v)}
          onEnter={() => createClient()}
        />
        <button onClick={() => createClient()}>Create Client</button>
      </div>
      <div>
        <Linput
          label="Enter brand json"
          name="enter-brand"
          value={brandInput}
          onChange={(v) => updateBrandInput(v)}
          onEnter={() => createBrand()}
        />
        <button onClick={() => createBrand()}>Create Brand</button>
      </div>
      {!!notice && (
        <div>
          <h1>{notice}</h1>
        </div>
      )}
    </div>
  );
};

export default ManageBrands;
