import React from "react";
import fetch from "isomorphic-fetch";
import { Header } from "../../../components/common/Header";
import Linput from "../../../components/common/Linput";
import ItemDetails from "../components/location/ItemDetails";
import { store, actions } from "../rdx/manage-locations";

var styles = {
  container: {
    padding: "10",
  },
};

export default class ManageLocation extends React.Component<{}, any> {
  constructor(props) {
    super(props);
    this.state = store.getState();
    store.subscribe(() => this.setState(store.getState()));
  }

  updateSku(e) {
    store.dispatch(actions.updateSearchInput(e.target.value));
  }

  updateItem(key) {
    return (e) => {
      var selectedItem = this.state.item;
      selectedItem[key] = e.target.value;
      store.dispatch(actions.setItem(selectedItem));
    };
  }

  async getItem() {
    try {
      const { searchInput } = this.state;
      const data = await fetch(`/api/location/${searchInput}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      let location = await data.json();
      store.dispatch(actions.setItemContainerDisplay(true));
      store.dispatch(
        actions.setItem({
          locationId: location.id,
          status: location.status,
          locationType: location.type,
          skuOwner: location.skuOwner,
          coId: location.coId,
          clubId: location.clubId,
          clientId: location.clientId,
          sku: location.sku,
          title: location.title,
          available: location.available,
          onHand: location.onHand,
          height: location.height,
          length: location.length,
          width: location.width,
        }),
      );
    } catch (err) {
      console.error("Error getting location", err);
      alert(`Error: Location not found: ${JSON.stringify(err.responseText)}`);
    }
  }

  async saveItem() {
    try {
      const {
        clientId,
        coId,
        clubId,
        skuOwner,
        status,
        locationType,
        height,
        length,
        width,
      } = this.state.item;
      const request = await fetch(`/api/location/${this.state.searchInput}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          clientId,
          coId,
          clubId,
          skuOwner,
          status,
          type: locationType,
          height,
          length,
          width,
        }),
      });
      if (!request.ok) {
        throw new Error(await request.text());
      }
      alert(`Successfully saved changes`);
    } catch (err) {
      console.error("Error saving item:", err);
      alert(`Error: Could not save changes: ${err}`);
    }
  }

  clearItem() {
    store.dispatch(actions.clearItem());
  }

  render() {
    var ItemContainer = (
      <div>
        <ItemDetails
          item={this.state.item}
          updateItem={this.updateItem.bind(this)}
          isSkuManager={true}
        />
      </div>
    );

    return (
      <div>
        <Header url="/management">Location Management</Header>

        <div id="leftReceivers" style={styles.container}>
          <Linput
            label="Enter Location ID to search"
            name="enter-location-id"
            value={this.state.searchInput}
            onChange={(v) => store.dispatch(actions.updateSearchInput(v))}
            onEnter={this.getItem.bind(this)}
          />
          <button onClick={this.getItem.bind(this)}>Search</button>
        </div>
        {this.state.displayItemContainer ? ItemContainer : null}
        {this.state.displayItemContainer ? (
          <div style={{ fontSize: "1.3em" }}>
            <button onClick={this.saveItem.bind(this)}>Save</button>
            <button
              style={{ margin: "20px", background: "red" }}
              onClick={this.clearItem.bind(this)}
            >
              Clear
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}
