import React from "react";

interface SimpleSelectProps {
  onChange?: (e: any, value: any) => void;
  options: any[];
  text?: string;
}

export class SimpleSelect extends React.Component<SimpleSelectProps> {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    if (!this.props.onChange) return;
    this.props.onChange(e, e.target.options[e.target.selectedIndex].value);
  }
  render() {
    const options = this.props.options.map(function (o, i) {
      return (
        <option value={o.value} key={i + 1}>
          {o.displayText}
        </option>
      );
    });
    const tops = this.props.text ? (
      <option value="" key={0}>
        {this.props.text}
      </option>
    ) : (
      ""
    );
    if (tops === "") {
      return <select onChange={this.onChange}>{options}</select>;
    }
    return (
      <select onChange={this.onChange}>
        {tops}
        {options}
      </select>
    );
  }
}

interface SpinnerProps {
  hide: boolean;
  imgSrc?: string;
}

export class Spinner extends React.Component<SpinnerProps> {
  render() {
    const style = this.props.hide ? { display: "none" } : {};
    const imgSrc = this.props.imgSrc ? this.props.imgSrc : "/img/loading.gif";
    return (
      <div id="spinner-wrapper" style={style}>
        <img id="spinner" src={imgSrc} alt="loading" />
      </div>
    );
  }
}

interface TextBoxProps {
  onChange?: (e: any, value: any) => void;
  onSubmit: () => void;
  text: string;
  placeholder: string;
}

export class TextBox extends React.Component<TextBoxProps> {
  onChange(e) {
    this.props.onChange(e, e.target.value);
  }
  onKeyUp(e) {
    if (e.key === "Enter") {
      return this.props.onSubmit();
    }
  }
  render() {
    return (
      <input
        type="text"
        value={this.props.text}
        onChange={this.onChange}
        onKeyUp={this.onKeyUp}
        placeholder={this.props.placeholder}
      />
    );
  }
}
