import { combineReducers, createStore } from "redux";

const searchOptions = [
  {
    value: "sku",
    displayText: "SKU",
  },
  {
    value: "title",
    displayText: "Title",
  },
  {
    value: "locationId",
    displayText: "Location ID",
  },
  {
    value: "upc",
    displayText: "UPC/ISBN",
  },
];

const toLocationSearchOptions = [
  { displayText: "Empty Back Locations Only", value: "empty-back" },
  { displayText: "Empty Flow Locations Only", value: "empty-flow" },
  {
    displayText: "Empty But Assigned Flow Locations Only",
    value: "flowAssigned",
  },
  {
    displayText: "Empty But Assigned Back Locations Only",
    value: "backAssigned",
  },
  { displayText: "Locations of Selected Sku", value: "currentSku" },
  { displayText: "Sku", value: "sku" },
  { displayText: "Location Id", value: "locationId" },
  { displayText: "Leaving Warehouse", value: "out" },
];

const SET_FROM_SEARCH_INPUT = "SET_FROM_SEARCH_INPUT";
const SET_FROM_SEARCH_TYPE = "SET_FROM_SEARCH_TYPE";
const SET_FROM_ITEMS = "SET_FROM_ITEMS";
const SET_MODAL_VISIBILITY = "SET_MODAL_VISIBILITY";
const SET_FROM_CURRENT_ITEM = "SET_FROM_CURRENT_ITEM";
const SET_TO_CURRENT_ITEM = "SET_TO_CURRENT_ITEM";
const SET_TO_ITEMS = "SET_TO_ITEMS";
const SET_TO_SEARCH_INPUT = "SET_TO_SEARCH_INPUT";
const SET_TO_SEARCH_TYPE = "SET_TO_SEARCH_TYPE";
const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
const SET_ADJUSTMENT_VALUE = "SET_ADJUSTMENT_VALUE";
const CLEAR_ADUSTMENT_PANEL = "CLEAR_ADUSTMENT_PANEL";
const UPDATE_REASON_COMMENTS = "UPDATE_REASON_COMMENTS";
const UPDATE_REASON_CODE = "UPDATE_REASON_CODE";
const UPDATE_ORDER_ID = "UPDATE_ORDER_ID";

export const actions = {
  setCurrentPage: (page) => {
    return { type: SET_CURRENT_PAGE, page };
  },

  clearAdjustmentPanel: () => {
    return { type: CLEAR_ADUSTMENT_PANEL };
  },

  setSearchType: (which, value) => {
    if (which === "from") return { type: SET_FROM_SEARCH_TYPE, value };
    if (which === "to") return { type: SET_TO_SEARCH_TYPE, value };
  },
  setSearchInput: (which, value) => {
    if (which === "from") return { type: SET_FROM_SEARCH_INPUT, value };
    if (which === "to") return { type: SET_TO_SEARCH_INPUT, value };
  },
  setItems: (which, items) => {
    if (which === "from") return { type: SET_FROM_ITEMS, items };
    if (which === "to") return { type: SET_TO_ITEMS, items };
  },
  toggleModal: (visible) => {
    return {
      type: SET_MODAL_VISIBILITY,
      visible,
    };
  },
  setCurrentItem: (which, item) => {
    if (which === "from") return { type: SET_FROM_CURRENT_ITEM, item };
    if (which === "to") return { type: SET_TO_CURRENT_ITEM, item };
  },
  setAdjustmentValue: (value) => {
    return { type: SET_ADJUSTMENT_VALUE, value };
  },
  updateReasonComments: (comments) => {
    return { type: UPDATE_REASON_COMMENTS, comments };
  },
  updateReasonCode: (code) => {
    return { type: UPDATE_REASON_CODE, code };
  },
  updateOrderId: (id) => {
    return { type: UPDATE_ORDER_ID, id };
  },
};

const adjustmentPanel = combineReducers({
  adjustmentValue: (state = 0, action) => {
    if (action.type === SET_ADJUSTMENT_VALUE) return action.value;
    if (action.type === CLEAR_ADUSTMENT_PANEL) return 0;
    return state;
  },

  reasonComments: (state = "", action) => {
    if (action.type === CLEAR_ADUSTMENT_PANEL) return "";
    if (action.type === UPDATE_REASON_COMMENTS) {
      return action.comments;
    }
    return state;
  },

  reasonCode: (state = "", action) => {
    if (action.type === CLEAR_ADUSTMENT_PANEL) return "";
    if (action.type === UPDATE_REASON_CODE) return action.code;
    return state;
  },

  orderId: (state = "", action) => {
    if (action.type === CLEAR_ADUSTMENT_PANEL) return "";
    if (action.type === UPDATE_ORDER_ID) return action.id;
    return state;
  },
});

const from = combineReducers({
  searchInput: (state = "", action) => {
    if (action.type === SET_FROM_SEARCH_INPUT) return action.value;
    return state;
  },

  searchType: (state = "sku", action) => {
    if (action.type === SET_FROM_SEARCH_TYPE) return action.value;
    return state;
  },

  items: (state = [], action) => {
    if (action.type === SET_FROM_ITEMS) return action.items;
    return state;
  },

  currentItem: (state = {}, action) => {
    if (action.type === SET_FROM_CURRENT_ITEM) return action.item;
    return state;
  },

  totalAvailable: (state = 0, action) => {
    if (action.type === SET_FROM_ITEMS) {
      return action.items.reduce((p, c) => p + c.available, 0);
    }
    return state;
  },

  totalOnHand: (state = 0, action) => {
    if (action.type === SET_FROM_ITEMS) {
      return action.items.reduce((p, c) => p + c.onHand, 0);
    }
    return state;
  },

  totalLocations: (state = 0, action) => {
    if (action.type === SET_FROM_ITEMS) return action.items.length;
    return state;
  },

  searchOptions: () => searchOptions,
});

const to = combineReducers({
  currentItem: (state = {}, action) => {
    if (action.type === SET_TO_CURRENT_ITEM) return action.item;
    return state;
  },

  items: (state = [], action) => {
    if (action.type === SET_TO_ITEMS) return action.items;
    return state;
  },

  searchInput: (state = "", action) => {
    if (action.type === SET_TO_SEARCH_INPUT) return action.value;
    return state;
  },

  searchType: (state = "empty-back", action) => {
    if (action.type === SET_TO_SEARCH_TYPE) return action.value;
    return state;
  },

  totalAvailable: (state = 0, action) => {
    if (action.type === SET_TO_ITEMS) {
      return action.items.reduce((p, c) => p + c.available, 0);
    }
    return state;
  },
  totalOnHand: (state = 0, action) => {
    if (action.type === SET_TO_ITEMS) {
      return action.items.reduce((p, c) => p + c.onHand, 0);
    }
    return state;
  },

  totalLocations: (state = 0, action) => {
    if (action.type === SET_TO_ITEMS) return action.items.length;
    return state;
  },

  searchOptions: () => toLocationSearchOptions,
});

const InventoryMoveApp = combineReducers({
  showModal: (state = false, action) => {
    if (action.type === SET_MODAL_VISIBILITY) return action.visible;
    return state;
  },

  step: () => 3,

  currentPage: (state = 0, action) => {
    if (action.type === SET_CURRENT_PAGE) return action.page;
    return state;
  },
  from,
  to,
  adjustmentPanel,
});

export const store = createStore(InventoryMoveApp);

// export const actionTypes = xtend(
//   {},
//   adjustmentActionTypes,
//   {
//     SET_TO_LOCATION_SEARCH_TYPE : 'SET_TO_LOCATION_SEARCH_TYPE',
//     SET_TO_LOCATION_SEARCH_TEXT : 'SET_TO_LOCATION_SEARCH_TEXT',
//     SET_TO_LOCATION_SEARCH_ITEMS: 'SET_TO_LOCATION_SEARCH_ITEMS',
//     SET_PAGES : 'SET_PAGES',
//     SET_CURRENT_PAGE : 'SET_CURRENT_PAGE',
//     SET_TO_LOCATION_SELECTED_ITEM : 'SET_TO_LOCATION_SELECTED_ITEM',
//     SET_SELECTED_REASON_CODE : 'SET_SELECTED_REASON_CODE',
//     SET_ORDER_ID : 'SET_ORDER_ID',
//     SET_NOTIFICATION_VISIBILITY : 'SET_NOTIFICATION_VISIBILITY',
//     SET_NOTIFICATION_TEXT : 'SET_NOTIFICATION_TEXT'
//   }
// );
//
// export const actions = xtend(
//   {},
//   adjustmentActions,
//   {
//     setToLocationSearchType : createSetter(actionTypes.SET_TO_LOCATION_SEARCH_TYPE, 'searchType'),
//     setToLocationSearchText : createSetter(actionTypes.SET_TO_LOCATION_SEARCH_TEXT, 'searchText'),
//     setToLocationSearchItems : createSetter(actionTypes.SET_TO_LOCATION_SEARCH_ITEMS, 'items'),
//     setPages : createSetter(actionTypes.SET_PAGES, 'pages'),
//     setCurrentPage : createSetter(actionTypes.SET_CURRENT_PAGE, 'page'),
//     setSelectedToItem : createSetter(actionTypes.SET_TO_LOCATION_SELECTED_ITEM, 'item'),
//     setOrderId : createSetter(actionTypes.SET_ORDER_ID, 'id'),
//     setNotificationVisibility : createSetter(actionTypes.SET_NOTIFICATION_VISIBILITY, 'visibility'),
//     setNotificationText : createSetter(actionTypes.SET_NOTIFICATION_TEXT, 'text')
//   }
// );
//
// export const movesApp = combineReducers({
//   inventoryItemsList : createSetterReducer([], actionTypes.SET_INVENTORY_ITEMS_LIST, 'items'),
//   showSpinner : createSetterReducer(false,actionTypes.SET_SPINNER_VISIBILITY, 'visibility'),
//   showModal : createSetterReducer(false,actionTypes.SET_MODAL_VISIBILITY, 'visibility'),
//   selectedItem : createSetterReducer({},actionTypes.SET_SELECTED_ITEM, 'item'),
//   error : createSetterReducer('', actionTypes.SET_ERROR, 'error'),
//   adjustmentValue : createSetterReducer(0,actionTypes.SET_ADJUSTMENT_VALUE, 'adjustment'),
//   selectedReasonCode : createSetterReducer('',actionTypes.SET_REASON_CODE, 'reasonCode'),
//   reasonComments : createSetterReducer('', actionTypes.SET_REASON_COMMENTS, 'reasonComments'),
//   searchText : createSetterReducer('', actionTypes.SET_SEARCH_TEXT, 'searchText'),
//   searchType : createSetterReducer('',actionTypes.SET_SEARCH_TYPE, 'searchType'),
//   toLocationSearchType : createSetterReducer('empty-back', actionTypes.SET_TO_LOCATION_SEARCH_TYPE, 'searchType'),
//   toLocationSearchText : createSetterReducer('', actionTypes.SET_TO_LOCATION_SEARCH_TEXT, 'searchText'),
//   toLocationItems : createSetterReducer([], actionTypes.SET_TO_LOCATION_SEARCH_ITEMS, 'items'),
//   selectedToItem : createSetterReducer({}, actionTypes.SET_TO_LOCATION_SELECTED_ITEM, 'item'),
//   orderId : createSetterReducer('', actionTypes.SET_ORDER_ID, 'id'),
//   showNotification : createSetterReducer(false, actionTypes.SET_NOTIFICATION_VISIBILITY, 'visibility'),
//   notificationText : createSetterReducer('', actionTypes.SET_NOTIFICATION_TEXT, 'text'),
//   step : () => { return 5; },
//   currentPage : createSetterReducer(0, actionTypes.SET_CURRENT_PAGE, 'page'),
//   pages : createSetterReducer(0, actionTypes.SET_PAGES, 'pages'),
//   searchOptions : () => {return searchOptions; },
//   toLocationSearchOptions : () => { return toLocationSearchOptions; }
// });
