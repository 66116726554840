// Helper methods to be used in print_queue.tsx
import { QueueStats } from "../../api/print/PrintDb";

// THE ORDER OF THESE STRINGS IS VERY IMPORTANT (DON'T MESS WITH IT)
const PRINT_QUEUE_GROUP_CHECKS = [
  "FriendBookmarkGeneric",
  "FriendBookmarkNonGeneric",
  "BFFBookmark",
  "Conversion",
  "WelcomeBooklet",
  "default",
  "giftCard",
  "RewardInsert",
  "FreeStory",
];

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// QueueGroup Helper Type used only in print-queue-select.tsx
export type QueueGroup = QueueStats & { subQueues: QueueStats[] };

// Helper method that categorizes a given queue into a specific queue group
export const categorizeQueue = (queueName: string): string => {
  const groupArrayToUse = PRINT_QUEUE_GROUP_CHECKS;
  const possibleGroup = groupArrayToUse.find((group) =>
    queueName.includes(group),
  );
  if (queueName.includes("UPS") || queueName.includes("DHL")) {
    return queueName;
  } else if (possibleGroup === "WelcomeBooklet") {
    if (queueName.includes("FreeStory")) {
      return "WelcomeBookletFreeStory";
    }
    return `WelcomeBooklet`;
  } else if (
    ["BFFBookmark", "Conversion", "RewardInsert"].includes(possibleGroup)
  ) {
    if (queueName.includes("FreeStory")) {
      return `defaultFreeStory`;
    }
    return `default`;
  } else if (possibleGroup === "FreeStory") {
    return `defaultFreeStory`;
  } else if (possibleGroup === undefined) {
    const isMonthly = MONTHS.filter((m) => queueName.includes(m)).length > 0;
    if (isMonthly) {
      return `Monthly+Other`;
    } else {
      return queueName;
    }
  } else {
    return possibleGroup;
  }
};

// Helper method that adds a given queue's data into a queue group for display purposes
export const addQueueToGroup = (
  queueStats: QueueStats,
  queueGroup: QueueGroup,
): QueueGroup => ({
  name: queueGroup.name,
  totalOrders: queueGroup.totalOrders + queueStats.totalOrders,
  totalSlaIssues: queueGroup.totalSlaIssues + queueStats.totalSlaIssues,
  totalMachineableOrders:
    queueGroup.totalMachineableOrders + queueStats.totalMachineableOrders,
  totalBulkSatoOrders:
    queueGroup.totalBulkSatoOrders + queueStats.totalBulkSatoOrders,
  totalBulkSatoSlaIssues:
    queueGroup.totalBulkSatoSlaIssues + queueStats.totalBulkSatoSlaIssues,
  bulkSato: queueGroup.bulkSato && queueStats.bulkSato,
  machineable: queueGroup.machineable && queueStats.machineable,
  subQueues: [...queueGroup.subQueues, queueStats],
});
