import React from "react";
import { useState } from "react";
import { Header } from "../../../components/common/Header";
import Linput from "../../../components/common/Linput";

let style = {
  mainDiv: {
    textAlign: "center" as "center",
  },
  input: {
    float: "none",
  },
};

const UnlockOrder: React.FC = (props) => {
  const [lockInput, updateLockInput] = useState("");
  const [notice, setNotice] = useState(null);
  const unlockOrder = async () => {
    const response = await fetch(`/api/print/clear_lock`, {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ lockKey: lockInput }),
    });
    if (!response.ok) {
      // Don't expect this to error; but if it does show it.
      setNotice("Error: " + (await response.text()));
    } else {
      // Just show the locl clear accepted message for user feedback
      setNotice(await response.text());
    }
  };

  return (
    <div style={style.mainDiv}>
      <Header url="./">Unlock Order Dashboard</Header>
      <div>
        <Linput
          label="Example: 268c3949-5a23-4819-b2a3-b5d5799de15c (use full lock key instead of shipment id)"
          name="enter-lockkey"
          value={lockInput}
          onChange={(v) => updateLockInput(v)}
          onEnter={() => unlockOrder()}
        />
        <button onClick={() => unlockOrder()}>Unlock Order</button>
      </div>
      {notice && (
        <div>
          <h1>{notice}</h1>
        </div>
      )}
    </div>
  );
};

export default UnlockOrder;
