import React from "react";
import fetch from "isomorphic-fetch";
import { actions, store } from "../rdx/pending-moves";
import { withCurrentUser } from "../../../contexts/UserContext";
import { MoveItem } from "../../../components/Moves/MoveItem";
import { Header } from "../../../components/common/Header";
import FakeTable from "../../../components/common/FakeTable";
import Spinner from "../../../components/common/Spinner";
import { User } from "../../../contexts/UserContext";

interface PendingMovesProps {
  currentUser: User;
}

class PendingMoves extends React.Component<PendingMovesProps, any> {
  constructor(props) {
    super(props);
    this.state = store.getState();
    this.getMoves();
  }

  componentWillMount() {
    store.subscribe(() => this.setState(store.getState()));
  }

  async completeMove(moveId) {
    const answer = confirm("Are you sure you want to complete this move?");
    if (!answer) return;
    store.dispatch(actions.setSpinnerVisibility(true));
    try {
      await fetch(`/api/move/complete/${moveId}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });
      await setTimeout(
        () => store.dispatch(actions.setSpinnerVisibility(false)),
        2000,
      );
      return this.getMoves();
    } catch (error) {
      console.error("Error completing move:", error);
    } finally {
      store.dispatch(actions.setSpinnerVisibility(false));
    }
  }

  async unlockMove(moveId) {
    store.dispatch(actions.setSpinnerVisibility(true));
    try {
      await fetch(`/api/move/unlock/${moveId}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          lockUserId: this.props.currentUser.id,
        }),
      });
      this.getMoves();
    } catch (error) {
      console.error("Error locking move:", error);
    } finally {
      store.dispatch(actions.setSpinnerVisibility(false));
    }
  }

  async getMoves() {
    try {
      const data = await fetch(
        `/api/move?status=PENDING&excludeMoveTypes=RECEIVE`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        },
      );
      const movesData = await data.json();
      const insertId =
        typeof this.state.inserts[0] === "undefined"
          ? ""
          : this.state.inserts[0].insertId;
      const moves = movesData.map((m) => {
        let ret = Object.assign({}, m);
        if (m.lockUserId) ret.className = "taken-move";
        if (m.lockUserId === this.props.currentUser.id)
          ret.className = "users-move";
        return ret;
      });
      const sorted = moves
        .sort((a, b) => {
          if (a.shipmentId < b.shipmentId) return 1;
          if (a.shipmentId > b.shipmentId) return -1;
          if (a.dropOff > b.dropOff) return -1;
          if (a.dropOff < b.dropOff) return 1;
          if (a.pickup < b.pickup) return -1;
          if (a.pickup > b.pickup) return 1;
          return 0;
        })
        .reverse();
      store.dispatch(actions.setMovesList(sorted));
      this.updateInsert(insertId);
    } catch (error) {
      console.error("Error getting moves:", error);
      alert("Error getting moves!");
    }
  }

  async lockMove(moveId) {
    store.dispatch(actions.setSpinnerVisibility(true));
    try {
      await fetch(`/api/move/lock/${moveId}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          lockUserId: this.props.currentUser.id,
        }),
      });
      this.getMoves();
    } catch (error) {
      console.error("Error locking move:", error);
    } finally {
      store.dispatch(actions.setSpinnerVisibility(false));
    }
  }

  async deleteMove(moveId) {
    const isSure = confirm("Are you sure you want to delete this move?");
    if (!isSure) return;
    store.dispatch(actions.setSpinnerVisibility(true));
    try {
      await fetch(`/api/move/${moveId}`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      });
      alert("Move Deleted");
      this.getMoves();
    } catch (error) {
      console.error("Error deleting move:", error);
      alert("Unable to delete move.");
    } finally {
      store.dispatch(actions.setSpinnerVisibility(false));
    }
  }

  updateInsert(moveId) {
    const move = this.state.movesList.filter((m) => m.id === moveId)[0];
    if (!move) return;
    const index = this.state.movesList.indexOf(move);
    let buttonInnards = `Lock as ${this.props.currentUser.username}`;
    let btnOnClick = this.lockMove.bind(this, move.id);
    let completeEl;
    let className = index % 2 === 0 ? "ft-even" : "ft-odd";
    let deleteEl = null;
    if (move.lockUserId === this.props.currentUser.id) {
      className = "users-move";
      buttonInnards = "Unlock";
      btnOnClick = this.unlockMove.bind(this, move.id);
      completeEl = (
        <button
          style={{ marginLeft: "10px" }}
          onClick={this.completeMove.bind(this, move.id)}
        >
          Complete
        </button>
      );
      deleteEl = (
        <button
          style={{ background: "red", marginLeft: "9%" }}
          onClick={this.deleteMove.bind(this, move.id)}
        >
          Delete Move
        </button>
      );
    } else if (move.lockUserId) {
      buttonInnards = `Taken by user ${move.lockUserId}`;
      btnOnClick = () => this.completeMove.bind(this, move.id);
    }
    var insert = {
      insertId: move.id,
      className: className,
      el: (
        <MoveItem
          title={move.title}
          sku={move.sku}
          pickup={move.pickup}
          dropOff={move.dropOff}
          quantity={move.moveQuantity}
          orderId={move.orderId}
        >
          <button onClick={btnOnClick}>{buttonInnards}</button>
          {completeEl}
          {deleteEl}
        </MoveItem>
      ),
    };
    store.dispatch(actions.setInserts([insert]));
  }

  render() {
    return (
      <div>
        <Spinner show={this.state.showSpinner} />
        <Header url="./">Inventory Dashboard - Move</Header>
        <FakeTable
          header={this.state.movesHeaders}
          data={this.state.movesList}
          keys={this.state.movesKeys}
          inserts={this.state.inserts}
          rowClicked={(move) => {
            this.updateInsert(move.id);
          }}
        />
      </div>
    );
  }
}

export default withCurrentUser(PendingMoves);
