import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import handlebars from "handlebars";
import bwipjs from "bwip-js";
import initData from "./initialData";

const renderBarcode = (c, opts) =>
  new Promise((resolve, reject) => {
    bwipjs(c, opts, (err) => (err ? reject(err) : resolve(null)));
  });

const getVisibleBarcode = (visibleBarcode) => {
  return (
    "(" +
    visibleBarcode.slice(0, 3) +
    ")" +
    visibleBarcode.slice(3, 8) +
    "(" +
    visibleBarcode.slice(8, 10) +
    ")" +
    visibleBarcode.slice(10)
  );
};

interface TemplateData {
  id?: number;
  name?: string;
  template?: string;
  renderWidth?: string;
  renderHeight?: string;
}

const defaultData = {
  id: 0,
  name: "",
  template: "",
  renderWidth: "4",
  renderHeight: "5",
};

const InvoiceRender = () => {
  const [data, setData] = useState(initData);
  const [templateData, setTemplateData] = useState<TemplateData>(defaultData);
  const { id } = useParams<{ id }>();

  const getTemplate = async () => {
    let res = await fetch(`/api/template/${id}`, {
      method: "GET",
      credentials: "include",
    });
    const {
      id: templateId,
      name,
      template,
      renderWidth,
      renderHeight,
    } = await res.json();
    setTemplateData({
      id: templateId,
      name,
      template,
      renderWidth,
      renderHeight,
    });
  };

  const setBarcode = async () => {
    const visibleBarcode = getVisibleBarcode(data.data.visible_barcode);
    const renderableCode = visibleBarcode;
    const canvas = document.createElement("canvas");
    const opts = {
      bcid: "gs1-128",
      text: renderableCode,
    };
    await renderBarcode(canvas, opts);
    const barcode = canvas.toDataURL();
    const updatedData = { ...data.data, barcode };
    setData({ ...data, data: updatedData });
  };

  useEffect(() => {
    getTemplate();
  }, []);
  useEffect(() => {
    setBarcode();
  }, [data.data.visible_barcode]);

  const setIframe = (iframe, templateString) => {
    if (iframe) {
      const content =
        iframe.contentWindow ||
        iframe.contentDocument.document ||
        iframe.contentDocument;

      const handlebarsTemplate = handlebars.compile(templateString);

      content.document.open();
      content.document.write(handlebarsTemplate(data));
      content.document.close();
    }
  };

  const globalSetData = async (input) => {
    for (let key in input) {
      if (input[key] && input[key].barcode) {
        const canvas = document.createElement("canvas");
        await renderBarcode(canvas, input[key].opts);
        input[key] = canvas.toDataURL();
      }
    }
    input = { ...data.data, ...input };
    setData({ data: input });
  };

  window.globalSetData = globalSetData;

  return (
    <iframe
      style={{
        minHeight: templateData.renderHeight,
        minWidth: templateData.renderWidth,
      }}
      ref={(iframe) => {
        setIframe(iframe, templateData.template);
      }}
    />
  );
};

export default InvoiceRender;
