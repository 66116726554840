import React from "react";
import { Header } from "../../components/common/Header";
import { Redirect } from "react-router-dom";
import { useCurrentClient } from "../../contexts/ClientContext";
import { Link, useRouteMatch } from "react-router-dom";

let styles = {
  block: {
    backgroundColor: "lightgreen",
    fontSize: "x-large",
    lineHeight: "3em",
    width: 200,
    float: "left" as "left",
    textAlign: "center" as "center",
    textDecoration: "none",
    textTransform: "uppercase" as "uppercase",
    border: "1px solid grey",
    padding: "0 20px",
    height: 150,
  },
};

const Inventory: React.FC = (props) => {
  const client = useCurrentClient();
  const { url } = useRouteMatch();
  if (!client) {
    return <Redirect to="/client" />;
  }
  return (
    <div>
      <Header url="/">{client.name + " Inventory Dashboard"}</Header>

      <div id="mainNav" className="block-center">
        <Link to={url + "/adjustment"} style={styles.block}>
          Adjust Inventory
        </Link>
        <Link to={url + "/move"} style={styles.block}>
          Move Inventory
        </Link>
        <Link to={url + "/freeze"} style={styles.block}>
          (UN) Freeze Location
        </Link>
        <Link to={url + "/pending-moves"} style={styles.block}>
          Pending Moving
        </Link>
      </div>
    </div>
  );
};

export default Inventory;
