import React from "react";
import { prettifyLocation } from "../../utils/prettify-location";
import { getProductImage } from "../../utils/get-product-image";

interface MoveItemInterface {
  sku: string;
  title: string;
  quantity: number;
  pickup: string;
  dropOff: string;
  orderId?: string;
}

export class MoveItem extends React.Component<MoveItemInterface> {
  render() {
    return (
      <div>
        <img src={getProductImage(this.props.sku)} alt="" />
        <ul style={{ width: "350px" }}>
          <li>{this.props.title}</li>
          <li>Sku : {this.props.sku}</li>
          <li>Qty : {this.props.quantity}</li>
          <li>
            From :{" "}
            {/^\d+$/.test(this.props.pickup) === true
              ? prettifyLocation(this.props.pickup)
              : this.props.pickup}
          </li>
          <li>
            To :{" "}
            {/^\d+$/.test(this.props.dropOff) === true
              ? prettifyLocation(this.props.dropOff)
              : this.props.dropOff}
          </li>
          <li>B Order Id : {this.props.orderId}</li>
        </ul>
        <div>{this.props.children}</div>
      </div>
    );
  }
}
