import React from "react";
import { useState } from "react";
import { Header } from "../../../components/common/Header";
import Linput from "../../../components/common/Linput";
import FakeTable from "../../../components/common/FakeTable";
import { Redirect } from "react-router-dom";
import { Link, useRouteMatch } from "react-router-dom";

const PrintToil: React.FC = (props) => {
  const [searchInput, setSearchInput] = useState("");
  const [shipment, setShipment] = useState(null);
  const [toil, setToil] = useState(null);
  const [notice, setNotice] = useState(null);
  const { url } = useRouteMatch();

  const searchToil = async () => {
    setShipment(null);
    setNotice("Searching...");
    const response = await fetch(
      `/api/print/search-toil?search=${searchInput}`,
      {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    if (response.status !== 200) {
      setNotice(await response.text());
    } else {
      const shipment = await response.json();
      setShipment(shipment);
      setNotice(null);
    }
  };

  const listToil = async () => {
    const response = await fetch(`/api/print/list-toil`, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status !== 200) {
      setNotice(await response.text());
      setToil(null);
    } else {
      const toil = await response.json();
      setToil(toil);
      setNotice(null);
    }
  };

  const markToil = async () => {
    const response = await fetch(`/api/print/mark-toil`, {
      credentials: "include",
      method: "POST",
      body: JSON.stringify({ id: shipment.id }),
      headers: { "Content-Type": "application/json" },
    });
    if (response.status !== 200) {
      setToil(null);
      setShipment(null);
      setNotice(await response.text());
    } else {
      await listToil();
    }
  };

  React.useEffect(() => {
    listToil();
  }, []);

  return (
    <div>
      <Header url="../">{"Print Toil"}</Header>
      <div>
        <div>{notice}</div>
        <Linput
          name="enter-invoice-number"
          value={searchInput}
          onChange={(v) => setSearchInput(v)}
          onEnter={() => searchToil()}
        />
        <button onClick={() => searchToil()}>Search Barcode/ShipmentID</button>
      </div>
      {shipment ? (
        <div>
          Order: <span>{shipment.id}</span>
          <br />
          Barcode: <span>{shipment.barcode}</span>
          <br />
          Data: <span>{shipment.gift_data}</span>
          <br />
          Zipcode: <span>{shipment.zipcode}</span>
          <br />
          <button onClick={() => markToil()}>Mark Toil</button>
        </div>
      ) : (
        <div />
      )}
      <div>
        <button onClick={() => listToil()}>Refresh Pending Toil</button>
        {toil ? (
          <FakeTable
            header={Object.keys(toil[0])}
            data={toil}
            keys={Object.keys(toil[0])}
            inserts={[]}
            rowClicked={() => {}}
          />
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

export default PrintToil;
