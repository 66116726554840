import React from "react";
import { useState, useContext, useEffect } from "react";
import fetch from "isomorphic-fetch";
interface genericFunction {
  (props?: any): any;
}
interface User {
  id?: number;
  username: string;
  level: number;
  email: string;
}

interface UserContextValue {
  currentUser: User;
  login: (username: string, password: string) => Promise<User>;
  logout: () => Promise<void>;
  firstLoginAttempted: boolean;
  userList?: User[];
  warehouseLocation: string;
}

const useAsyncEffect = (asyncFn: genericFunction, deps: any[]) => {
  useEffect(() => {
    let cancelled = false;
    (async () => {
      const syncFn = await asyncFn();
      if (!cancelled && syncFn) {
        syncFn();
      }
    })();
    return () => {
      cancelled = true;
    };
  }, deps);
};

const UserContext = React.createContext<UserContextValue>({
  currentUser: null,
  firstLoginAttempted: false,
  login: null,
  logout: null,
  warehouseLocation: null,
});

const UserProvider: React.FC = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [firstLoginAttempted, setLoginAttempted] = useState(false);
  const [warehouseLocation, setWarehouseLocation] = useState(null);

  const login = async (username, password): Promise<User> => {
    const response = await fetch(`/api/login`, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status !== 200) {
      throw new Error("Invalid credentials provided");
    }
    const user = await response.json();
    setCurrentUser(user);
    return user;
  };
  const logout = async () => {
    const response = await fetch(`/api/logout`, {
      method: "POST",
      body: JSON.stringify({}),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    setCurrentUser(null);
  };
  const getCurrentUser = async () => {
    const response = await fetch(`/api/current_user`, {
      credentials: "include",
    });
    if (response.status !== 200) {
      throw new Error("Not currently logged in");
    }
    return response.json();
  };
  const getWarehouseLocation = async () => {
    const response = await fetch(`/api/user/warehouseLocation`, {
      credentials: "include",
    });
    if (response.status !== 200) {
      throw new Error("Error fetching warehouse location");
    }
    const { warehouseLocation } = await response.json();
    return warehouseLocation;
  };
  useAsyncEffect(async () => {
    let user = null;
    let warehouseLocation = null;
    try {
      user = await getCurrentUser();
      warehouseLocation = await getWarehouseLocation();
    } catch (err) {
      // ignore
    }
    return () => {
      setCurrentUser(user);
      setWarehouseLocation(warehouseLocation);
      setLoginAttempted(true);
    };
  }, []);
  return (
    <UserContext.Provider
      value={{
        currentUser,
        login,
        logout,
        firstLoginAttempted,
        warehouseLocation,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useCurrentUser = () => {
  const { currentUser } = useContext(UserContext);
  return currentUser;
};

const withCurrentUser = (Component) => {
  const WithCurrentUser = (props) => {
    const currentUser = useCurrentUser();
    return <Component {...props} currentUser={currentUser} />;
  };
  return WithCurrentUser;
};

export { UserProvider, UserContext, useCurrentUser, withCurrentUser, User };
